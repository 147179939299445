import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPhoneNumber } from "app/services/createRequirmentApi";
import { APP_ROUTES } from "constants/Routes";
import { USER_ROLE_NAME } from "constants/Taboptions";

import { authApi } from "../../app/services/auth";
import { RootState } from "../../app/store";

export interface IUserRole {
  _id: string;
  name: USER_ROLE_NAME;
  type: string;
  department: string;
  dataAccessLevel: number;
  createdAt: string;
  updatedAt: string;
}

export interface IUser {
  _id: string;
  name: string;
  email: string;
  phoneNumber: IPhoneNumber;
  role: IUserRole;
  subRoles: (typeof USER_ROLE_NAME)[keyof typeof USER_ROLE_NAME][];
  modules: string[];
  profitCenters: string[];
}

export type AuthState = {
  isAuth: boolean;
  user: IUser | null;
  defaultRoute: string;
};

const initialState: AuthState = {
  isAuth: false,
  user: null,
  defaultRoute: `${APP_ROUTES.PROPERTIES}/${APP_ROUTES.PROPERTIES_NEW}`,
};
export type LoginSuccessAction = PayloadAction<{
  user: IUser;
}>;

export const getDefaultRoute = (userRole?: string) => {
  if (
    userRole === USER_ROLE_NAME.BRAND_MANAGER_ASSOCIATE ||
    userRole === USER_ROLE_NAME.BRAND_MANAGER_ASSOCIATE_MARKER
  ) {
    return APP_ROUTES.REQUIREMENT;
  } else if (userRole === USER_ROLE_NAME.MARKER) {
    return APP_ROUTES.MARKER;
  } else if (
    userRole === USER_ROLE_NAME.BRAND_MANAGER_LEAD ||
    userRole === USER_ROLE_NAME.BRAND_MANAGER_LEAD_MARKER ||
    userRole === USER_ROLE_NAME.CENTRAL_TEAM
  ) {
    return `${APP_ROUTES.ANALYTICS}/${APP_ROUTES.WIP_ANALYTICS}`;
  } else if (userRole === USER_ROLE_NAME.FINANCE) {
    return `${APP_ROUTES.REVENUE}${APP_ROUTES.INVOICES}/${APP_ROUTES.LISTING}`;
  } else if (userRole === USER_ROLE_NAME.INCENTIVE_TEAM) {
    return `${APP_ROUTES.REVENUE_ALLOCATION}/${APP_ROUTES.REPORT}`;
  }
  return `${APP_ROUTES.PROPERTIES}/${APP_ROUTES.PROPERTIES_NEW}`;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutSuccess: (state) => {
      state.isAuth = false;
      state.user = null;
      state.defaultRoute = `${APP_ROUTES.PROPERTIES}/${APP_ROUTES.PROPERTIES_NEW}`;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      if (payload.success && payload.data) {
        state.isAuth = true;
        state.user = payload.data.user;
        state.defaultRoute = getDefaultRoute(payload?.data?.user?.role?.name);
      }
    });
    builder.addMatcher(authApi.endpoints.checkAuth.matchFulfilled, (state, { payload }) => {
      if (payload.success && payload.data) {
        state.isAuth = true;
        state.user = payload.data.user;
        state.defaultRoute = getDefaultRoute(payload?.data?.user?.role?.name);
      }
    });
    builder.addMatcher(authApi.endpoints.checkAuth.matchRejected, (state) => {
      state.isAuth = false;
      state.user = null;
      state.defaultRoute = `${APP_ROUTES.PROPERTIES}/${APP_ROUTES.PROPERTIES_NEW}`;
    });
  },
});

export const selectAuthUser = (state: RootState) => (state.auth as AuthState).user;
export const selectIsAuth = (state: RootState) => (state.auth as AuthState).isAuth;
export const selectUserDefaultRoute = (state: RootState) => (state.auth as AuthState).defaultRoute;
export const { logoutSuccess } = authSlice.actions;
export default authSlice.reducer;
