import { ArInput, ArInputType, ArRangeSlider, varientType } from "@anarock/ar-common-react";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useEffect, useState } from "react";

import { MinMaxFilterComponentProps } from "../../index.interface";
import styles from "../../index.module.scss";
import ResetApplyButtonComponent from "../ResetApplyButtonComponent";

type Timer = ReturnType<typeof setTimeout>;

const MinMaxFilterComponent: React.FC<MinMaxFilterComponentProps> = ({
  rangeValue,
  setRangeValue,
  value,
  onChange,
  min,
  max,
  isMoreFilter = false,
  setOpen,
  ...props
}) => {
  const timer = React.useRef<Timer>();
  const [inputValue, setInputValue] = useState([min, max]);

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    setInputValue([value?.min || min, value?.max || max]);
  }, [value]);

  const handleChange = (val: [number, number]) => {
    const [minValue, maxValue] = val;
    if (isNaN(minValue) || isNaN(maxValue)) {
      return;
    }
    setInputValue(val);
    if (isMoreFilter) {
      onChange({ min: minValue, max: maxValue });
    }
  };

  // const callOnChange = useCallback(
  //   (min: number, max: number) => {
  //     const newTimer = setTimeout(() => {
  //       onChange({ min, max });
  //     }, 1000);
  //     clearTimeout(timer.current);
  //     timer.current = newTimer;
  //   },
  //   [onChange]
  // );

  useEffect(() => {
    const [minInput, maxInput] = [...inputValue];
    if (minInput < maxInput && minInput < max && maxInput <= max) {
      setRangeValue([minInput, maxInput]);
      if (isMoreFilter) {
        onChange({ min: minInput, max: maxInput });
      }
    }
  }, [inputValue, setRangeValue, max]);

  const handleInputChange = (val: string, idx: 0 | 1) => {
    const numberVal = Number(val);
    const value = [...inputValue];
    value[idx] = numberVal;
    setInputValue(value);
  };

  const handleApply = () => {
    const [min, max] = [...inputValue];
    setOpen && setOpen(false);
    onChange && onChange({ min, max });
  };

  const handleReset = () => {
    setInputValue([min, max]);
    onChange && onChange({ min, max });
  };

  const isError = inputValue[0] >= inputValue[1];

  const isMinError = isError || inputValue[0] >= max;

  const isMaxError = isError || inputValue[1] > max;

  return (
    <>
      <div className={isMoreFilter ? "" : styles.sliderPadding}>
        <div className={styles.minMaxInputHover}>
          <div>
            <ArInput
              label={STRING_CONSTANTS.MIN}
              placeholder={STRING_CONSTANTS.MIN}
              value={inputValue[0]}
              type={ArInputType.text}
              onChange={(value) => handleInputChange(value as string, 0)}
              status={isMinError ? "error" : ""}
              description={isError ? "min<max" : inputValue[0] >= max ? `min<${max}` : ""}
              varient={isMinError ? varientType.error : varientType.default}
            />
          </div>
          <p className="sub-hero-text-regular-medium">{STRING_CONSTANTS.TO}</p>
          <div>
            <ArInput
              label={STRING_CONSTANTS.MAX}
              placeholder={STRING_CONSTANTS.MAX}
              value={inputValue[1]}
              type={ArInputType.number}
              onChange={(value) => handleInputChange(value as string, 1)}
              status={isMaxError ? "error" : ""}
              description={isError ? "max>min" : inputValue[1] > max ? `max<=${max}` : ""}
              varient={isMaxError ? varientType.error : varientType.default}
            />
          </div>
        </div>
        <ArRangeSlider
          range
          value={rangeValue}
          min={min}
          showValues={false}
          max={max}
          onChange={handleChange}
          autoFocus
        />
      </div>
      {!isMoreFilter ? <ResetApplyButtonComponent handleApply={handleApply} handleReset={handleReset} /> : null}
    </>
  );
};

export default MinMaxFilterComponent;
