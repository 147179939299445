import { ArBrandModals, ArButton, ArModal } from "@anarock/ar-common-react";
import { ButtonTypes } from "@anarock/ar-common-react";
import colors from "@anarock/ar-common-react/dist/styles/partials/_colors.module.scss";
import { optionProps } from "@anarock/ar-common-react/dist/ui-components/ar-checkbox/index.interface";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
  useGetConsultingWipDetailsQuery,
  useLazyGetConsultingWipStatusListQuery,
  useUpdateConsultingWipStatusMutation,
} from "app/services/consultingWip";
import { useGetStaticEnumsQuery } from "app/services/enums";
import { useGetOptionsListQuery } from "app/services/optionListApi";
import { isNullOrUndefiend } from "app/utils/helperFunctions";
import { IWipStatus } from "app/utils/interfaces/index.interface";
import DropWIPModal from "components/DropWIPModal";
import { useToastService } from "components/toastService";
import { WIP_APPROVAL_STATUS } from "constants/index";
import { OPTION_LIST_SHOP_STATUS } from "constants/ListingEnums";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import { initialOptionListState } from "features/requirementListingFlow/optionsList/optionlistSlice";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import IUpdateConsultingWipStatusModalProps from "./index.interface";
import styles from "./index.module.scss";
import DocumentUpload from "./partials";

const UpdateConsultingWipStatusModal = ({
  openModal,
  toggleModal,
  currentStatus,
  currentLocationId,
  projectId,
  submitState,
  submitStateAction,
}: IUpdateConsultingWipStatusModalProps) => {
  const { data: enumsData } = useGetStaticEnumsQuery();

  const enums = enumsData?.data;

  const DROP_THE_DEAL: string | number | boolean | undefined = enums?.wipStatus?.DROP_THE_DEAL;
  const NEGOTIATION = enums?.wipStatus?.NEGOTIATION;
  const [value, setValue] = useState(currentStatus || "");
  const [statusList, setStatusList] = useState<IWipStatus[]>([]);
  const [openReviveDealConfirmModal, setOpenReviveDealConfirmModal] = useState(false);
  const [notUploadedDocumentStructureIDArr, setNotUploadedDocumentStructureIDArr] = useState<string[]>([]);
  const [dropDealModal, setDropDealModal] = useState(false);

  const [openWipStatusAlertModal, setOpenWipStatusAlertModal] = useState(false);

  const [fetchStatusList, { isLoading }] = useLazyGetConsultingWipStatusListQuery();
  const [updateWipStatus] = useUpdateConsultingWipStatusMutation();
  const { data: wip } = useGetConsultingWipDetailsQuery(projectId || "", {
    skip: isNullOrUndefiend(projectId),
  });

  const navigate = useNavigate();

  const IS_DEAL_DROPPED = wip?.data?.wip?.approval?.approvalStatus === WIP_APPROVAL_STATUS.DROPPED;

  const submitButtonType = IS_DEAL_DROPPED ? ButtonTypes.PrimaryCoral : ButtonTypes.Primary;

  const color = IS_DEAL_DROPPED ? colors["coral-400"] : colors["primary-400"];

  const { data: optionsListApiRes } = useGetOptionsListQuery(
    {
      reqId: currentLocationId as string,
      params: {
        pageLimit: 100,
        pageNo: 1,
      },
    },
    {
      skip: isNullOrUndefiend(currentLocationId),
    }
  );
  const optionsList = optionsListApiRes?.data?.brandRequirementDetailOptionList || initialOptionListState;
  const inDraftWipCreated = optionsList[OPTION_LIST_SHOP_STATUS.DRAFT_WIP_CREATED];
  const inWipBilled = optionsList[OPTION_LIST_SHOP_STATUS.BILLED];
  const inWipCreated = optionsList[OPTION_LIST_SHOP_STATUS.WIP_CREATED];

  const { successToast, errorToast } = useToastService();

  useEffect(() => {
    if (projectId) {
      fetchStatusListHandler();
      setValue(currentStatus || "");
    }
  }, [projectId, currentStatus]);

  const getDocumentsUi = (val: string) => {
    if (val === DROP_THE_DEAL) {
      return;
    }
    const documentsHTML = [];
    for (let i = 0; i < statusList?.length; i++) {
      const statusObj = statusList[i];
      const documents = statusObj?.documents;
      if (documents?.length) {
        const doc = documents[0];
        documentsHTML.push(
          <div className={styles.mt16}>
            <DocumentUpload
              title={doc.title}
              documentStructureId={doc?.documentStructureId}
              allowedFileTypes={doc?.allowedTypes}
              documentURLs={doc?.documentURLs || []}
              refetchHandler={fetchStatusListHandler}
              notUploadedDocumentStructureIDArr={notUploadedDocumentStructureIDArr}
              color={color}
              projectId={projectId || ""}
            />
          </div>
        );
      }
      if (val === statusObj?.wipStatusId) {
        break;
      }
    }

    return documentsHTML;
  };
  const fetchStatusListHandler = async () => {
    const data = await fetchStatusList(projectId || "");

    setStatusList(data?.data?.data?.statusList || []);
  };
  const options: optionProps[] = statusList
    ?.filter(
      (status) => !(IS_DEAL_DROPPED && status?.wipStatusId === DROP_THE_DEAL) //if deal is dropped we will hide drop deal status from user.
    )
    ?.map((status, idx) => {
      const isSubmitted =
        status?.documents && status?.documents.length && !status?.documents?.some((item) => !item?.documentURLs);

      const label = (
        <>
          <p>
            <span className={`${status.wipStatusId === DROP_THE_DEAL ? styles.errorColor : ""}`}>{status.title}</span>
            {status?.documents?.length ? <span className={styles.required}> (Document Required) </span> : null}
            {isSubmitted ? <CheckCircleOutlined className={styles.greenCheckBox} /> : null}
          </p>
        </>
      );
      return {
        value: status.wipStatusId,
        label: label,
        footerNode:
          value === status.wipStatusId ? <div style={{ width: "98%" }}>{getDocumentsUi(value)}</div> : undefined,
      };
    });

  const confirmReviveDealSubmit = () => {
    handleUpdateStatusApi(NEGOTIATION);
    setOpenReviveDealConfirmModal(false);
  };

  const onSubmit = async (val: string | number | boolean) => {
    if (currentStatus === val) {
      toggleModal();
      submitStateAction?.();
      return;
    }
    if (currentStatus === DROP_THE_DEAL) {
      if (inDraftWipCreated?.length || inWipBilled?.length || inWipCreated?.length) {
        setOpenWipStatusAlertModal(true);
        toggleModal();
        return;
      }
      setOpenReviveDealConfirmModal(true);
      return;
    }
    let isError = false;
    const errorDocStrId: string[] = [];
    if (val === DROP_THE_DEAL) {
      setDropDealModal(true);
      toggleModal();
      return;
    } else {
      for (let i = 0; i < statusList?.length; i++) {
        const statusObj = statusList[i];
        const documents = statusObj?.documents;
        if (documents?.length) {
          const documentUrl = documents[0]?.documentURLs;
          if (!documentUrl) {
            errorDocStrId.push(documents[0]?.documentStructureId);
            isError = true;
            break;
          }
        }
        if (val === statusObj?.wipStatusId) {
          break;
        }
      }
    }

    if (isError) {
      setNotUploadedDocumentStructureIDArr(errorDocStrId);
      errorToast(TOAST_MESSAGES.UPLOAD_REQUIRED_DOCUMENTS);
      return;
    }
    handleUpdateStatusApi(val);
  };

  const handleUpdateStatusApi = async (val: string | number | boolean | undefined) => {
    const payload = {
      status: {
        wipStatusId: val,
      },
    };
    const data = await updateWipStatus({
      projectId: projectId || "",
      body: payload,
    }).unwrap();

    if (data?.success && val === DROP_THE_DEAL) {
      errorToast(TOAST_MESSAGES.DEAL_DROPPED_SUCCESSFULLY);
      toggleModal();
    } else if (data?.success && IS_DEAL_DROPPED) {
      successToast(TOAST_MESSAGES.DEAL_REVIVED_SUCCESSFULLY);
      toggleModal();
    } else if (data?.success) {
      successToast(TOAST_MESSAGES.STATUS_UPDATED_SUCCESSFULLY);
      toggleModal();
    } else {
      errorToast(data?.reason);
    }
    if (val === DROP_THE_DEAL) {
      navigate(APP_ROUTES.WIP + APP_ROUTES.EDIT_WIP + "/" + projectId + "/" + APP_ROUTES.WIP_SUMMARY);
      return;
    }
    submitStateAction?.();
  };
  if (isLoading) {
    return (
      <ArModal>
        <Spin />
      </ArModal>
    );
  }

  return (
    <>
      <ArBrandModals.ArControlledRadioOptionsModal
        open={openModal}
        onCancel={toggleModal}
        title={(submitState ? STRING_CONSTANTS.STEP_1_OFF_2 : "") + STRING_CONSTANTS.CHOOSE_WIP_STATUS}
        submitText={submitState ? STRING_CONSTANTS.UPDATE_AND_NEXT_STEP : STRING_CONSTANTS.UPDATE_WIP_STATUS}
        onSubmit={onSubmit}
        options={options}
        showSearch={false}
        defaultValue={value}
        onChange={(val: string) => setValue(val)}
        value={value}
        destroyOnClose
        submitButtonProps={{ type: submitButtonType }}
      ></ArBrandModals.ArControlledRadioOptionsModal>
      <ArModal
        open={openReviveDealConfirmModal}
        onCancel={() => setOpenReviveDealConfirmModal(false)}
        onSubmit={confirmReviveDealSubmit}
        title={STRING_CONSTANTS.REVIVING_DROPPED_DEAL}
        submitText={STRING_CONSTANTS.YES_REVIVE}
        footer={[
          <ArButton
            key="1"
            size={"large"}
            onClick={() => setOpenReviveDealConfirmModal(false)}
            type={ButtonTypes.OutlinedCoral}
          >
            {STRING_CONSTANTS.CANCEL}
          </ArButton>,

          <ArButton key="2" size={"large"} onClick={confirmReviveDealSubmit} type={submitButtonType}>
            {STRING_CONSTANTS.YES_REVIVE}
          </ArButton>,
        ]}
      >
        {STRING_CONSTANTS.REVIVING_DROP_DEAL}
        <ol>
          <li> {STRING_CONSTANTS.REVIVING_DROP_DEAL_STEP_1}</li>
          <li>{STRING_CONSTANTS.REVIVING_DROP_DEAL_STEP_2}</li>
        </ol>
        {STRING_CONSTANTS.SURE_YOU_WANT_TO_REVIVE}
        <br></br>
        <br></br>
      </ArModal>

      <ArModal
        open={openWipStatusAlertModal}
        onCancel={() => setOpenWipStatusAlertModal(false)}
        title={STRING_CONSTANTS.WIP_STATUS_UPDATE_ALERT}
        footer={[
          <ArButton key="1" size={"large"} onClick={() => setOpenWipStatusAlertModal(false)} type={submitButtonType}>
            {STRING_CONSTANTS.OK_UNDERSTOOD}
          </ArButton>,
        ]}
      >
        {STRING_CONSTANTS.WIP_STATUS_UPDATE_ALERT_MESSAGE}
      </ArModal>
      <DropWIPModal
        currentStatus={wip?.data?.wip?.status?.wipStatus?.title || ""}
        projectId={projectId || ""}
        open={dropDealModal}
        approval={wip?.data?.wip?.approval}
        onCancel={() => setDropDealModal(false)}
        closeModal={() => setDropDealModal(false)}
        isConsulting
        clientName={
          wip?.data?.wip?.brand?.brandInfo?.legalEntityName ||
          wip?.data?.wip?.landlord?.[0]?.landlordInfo?.legalEntityName ||
          STRING_CONSTANTS.CLIENT
        }
      />
    </>
  );
};

export default UpdateConsultingWipStatusModal;
