import { REPORTS_URLS } from "constants/ApiUrls";
import { ALLOCATION_STATUS } from "constants/index";

import { IAnalyticsTable, IFilterListResponse } from "./analytics";
import { api, GenericResponse } from "./api";
import { IFiltersRequest } from "./invoices";
import { IBrochureResponse } from "./optionListApi";

export interface IIRFUserAllocation {
  userId: string;
  userName: string;
  weightage: number;
}
export interface IIRFAllocation {
  clientName: string;
  irfId: string;
  allocatedRevenue: number;
  allocatedRevenuePerc: number;
  netRevenue: number;
  allocationStatus: string;
  irfUserAllocation: {
    allocationStatus: ALLOCATION_STATUS;
    allocation: IIRFUserAllocation[];
    pooledRevenuePercentage: number;
  };
  pooledRevenuePercentage: number;
}
export interface IAllocationRequest {
  profitCenter: string;
  pooledRevenuePercentage: number;
  netRevenue?: number;
  allocation: IIRFUserAllocation[];
  allocationStatus: ALLOCATION_STATUS;
  irfProjectId: string;
}

export const reportsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    revenueReportFilters: builder.query<IFilterListResponse, IFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `${REPORTS_URLS.REVENUE}${REPORTS_URLS.FILTERS}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    getRevenueReportTable: builder.query<GenericResponse<IAnalyticsTable>, IFiltersRequest>({
      query: ({ selectedFilters, params, sort }) => ({
        url: `${REPORTS_URLS.REVENUE}`,
        method: "POST",
        body: { selectedFilters, sort },
        params,
      }),
    }),
    exportRevenueReportExcel: builder.mutation<IBrochureResponse, IFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `${REPORTS_URLS.REVENUE}${REPORTS_URLS.EXPORT}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    collectionReportFilters: builder.query<IFilterListResponse, IFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `${REPORTS_URLS.COLLECTION}${REPORTS_URLS.FILTERS}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    getCollectionReportTable: builder.query<GenericResponse<IAnalyticsTable>, IFiltersRequest>({
      query: ({ selectedFilters, params, sort }) => ({
        url: `${REPORTS_URLS.COLLECTION}`,
        method: "POST",
        body: { selectedFilters, sort },
        params,
      }),
    }),
    exportCollectionReportExcel: builder.mutation<IBrochureResponse, IFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `${REPORTS_URLS.COLLECTION}${REPORTS_URLS.EXPORT}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    allocationReportFilters: builder.query<IFilterListResponse, IFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `${REPORTS_URLS.REVENUE_ALLOCATION}${REPORTS_URLS.REPORT}${REPORTS_URLS.FILTERS}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    getAllocationReportTable: builder.query<GenericResponse<IAnalyticsTable>, IFiltersRequest>({
      query: ({ selectedFilters, params, sort }) => ({
        url: `${REPORTS_URLS.REVENUE_ALLOCATION}${REPORTS_URLS.REPORT}`,
        method: "POST",
        body: { selectedFilters, sort },
        params,
      }),
      providesTags: ["AllocationReportTable"],
    }),
    getIRFAllocationHistoryTable: builder.query<
      GenericResponse<IAnalyticsTable>,
      { irfProjectId: string; profitCenter: string }
    >({
      query: ({ irfProjectId, profitCenter }) => ({
        url: `/wip/invoice/request/project/${irfProjectId}/allocation/table?profitCenter=${profitCenter}`,
        method: "GET",
      }),
      providesTags: ["AllocationReportTable"],
    }),
    exportAllocationReportExcel: builder.mutation<IBrochureResponse, IFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `${REPORTS_URLS.REVENUE_ALLOCATION}${REPORTS_URLS.REPORT}${REPORTS_URLS.EXPORT}`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    submitRevenueAllocation: builder.mutation<GenericResponse<void>, IAllocationRequest>({
      query: ({ irfProjectId, ...body }) => ({
        url: `/wip/invoice/request/project/${irfProjectId}/allocation`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["AllocationReportTable"],
    }),
    getRevenueAllocationDetails: builder.query<
      GenericResponse<{ irfAllocation: IIRFAllocation }>,
      { irfProjectId: string; profitCenter: string }
    >({
      query: ({ irfProjectId, profitCenter }) => ({
        url: `/wip/invoice/request/project/${irfProjectId}/allocation?profitCenter=${profitCenter}`,
        method: "GET",
      }),
      providesTags: ["AllocationReportTable"],
    }),
  }),
});

export const {
  useRevenueReportFiltersQuery,
  useGetRevenueReportTableQuery,
  useExportRevenueReportExcelMutation,
  useCollectionReportFiltersQuery,
  useGetCollectionReportTableQuery,
  useExportCollectionReportExcelMutation,
  useAllocationReportFiltersQuery,
  useGetAllocationReportTableQuery,
  useExportAllocationReportExcelMutation,
  useGetIRFAllocationHistoryTableQuery,
  useGetRevenueAllocationDetailsQuery,
  useSubmitRevenueAllocationMutation,
} = reportsApi;
