import { ArExtendedModalProps } from "app/utils/interfaces/helperFunctionInterfaces";
import GenericConfirmationModal from "components/appModals/genericConfirmationModal";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

export const RevenueAllocationConfirmationModal = ({
  open,
  onCancel,
  onSubmit,
  closeModal,
  ...props
}: ArExtendedModalProps) => {
  return (
    <GenericConfirmationModal
      body={<div className="pb-20">{STRING_CONSTANTS.REVENUE_ALLOCATION_CONFIRMATION_BODY}</div>}
      open={open}
      onCancel={onCancel}
      closeModal={closeModal}
      title={STRING_CONSTANTS.REVENUE_ALLOCATION_CONFIRMATION}
      submitText={STRING_CONSTANTS.CONFIRM_ALLOCATION}
      onSubmit={onSubmit}
      cancelText={STRING_CONSTANTS.NO_WAIT}
    ></GenericConfirmationModal>
  );
};
