import { IDocumentsUrL } from "app/utils/interfaces/index.interface";
import { CONSULTING_WIP_URLS, INVOICES_URLS } from "constants/ApiUrls";
import { IUser } from "features/authentication/authSlice";

import { IAnalyticsFilters, IFilterListResponse } from "./analytics";
import { api, GenericResponse, paginationParams } from "./api";
import { IComment, ISuggestedWIPInvoice } from "./collections";
import { IPhoneNumber } from "./createRequirmentApi";
import { IEnum } from "./enums";
import { IKYCObj } from "./kyc";
import { ICity, ISelectedFilters } from "./listingPageApi";
import { IImage, IPaginationParam } from "./notificationsApi";
import { IBrochureResponse } from "./optionListApi";
import { IUploadImageUrlResponse } from "./previewSheet";

export interface IIRFClient {
  type?: IPOCModule;
  clientLegalName?: string;
  clientId: string;
  clientObjectId: string;
}
export interface ICreateIRFBody {
  projectId: string;
  billingMilestoneKey: string;
  billingMilestone: string;
  clientType?: string;
  clientObjectId?: string;
  wipType?: string;
  invoiceRequestType?: string;
  creditStatus?: string;
}

export interface IIRFApproval {
  approvalStatus?: string;
  comment?: string;
  isSubmitted?: boolean | undefined;
  submittedAt?: string;
  revenueStatus?: string;
  invoiceStatus?: string;
  reason?: string[];
  creditStatus?: string;
}

export type IPOCModule = "Brand" | "LL" | undefined;
export interface IClientPOC {
  module?: IPOCModule;
  moduleEntryId?: string;
  _id?: string;
  name: string;
  email: string;
  phoneNumber: IPhoneNumber;
  alternatePhoneNumbers?: IPhoneNumber[];
  type?: string;
}
export interface IClientAddress {
  address?: string;
  cityName?: string;
  stateName?: string;
  pincode?: string;
}

export interface IIRFService {
  type: string;
  description: string;
}

export interface IAgrementDetails {
  // area: {
  //   value: string;
  //   unit: string;
  // };

  rent: {
    value: string;
    unit: string;
  };

  brokerageDays: string;

  service: IIRFService;

  feeAmount: string | number;
  invoiceAmount: string | number;

  credit: {
    period: number; //, enum: creditPeriodEnum },
    rating: string; //, enum: ceditRatingEnum },
  };

  thirdParty?: {
    name: string;
    feeAmount?: string;
    weightage?: string;
  };

  invoiceableAmount?: number;
  serviceFeeCalculationBasis1?: string;
  serviceFeeCalculationBasis2?: string;
  noOfDays: number;
  noOfYears: number;
  yearlyAverageRent: number;
  noOfMonths: number;
  lumpSump: number;
  securityDeposit: number;
  securityDepositMultiple: number;
  originalFeeAmount?: number;
  creditNoteFeeAmount?: number;
}

export interface IAllocation {
  profitCenter: string;
  weightage: number;
}
export interface IIRF {
  _id: string;
  irfId?: string;
  irfProjectId?: string;
  isSubmitted?: boolean;
  projectId?: string;
  billingMilestone?: string;
  client?: {
    type?: IPOCModule;
    _id: string;
    name: string;
    legalEntityName: string;
    industry: string;
    clientId?: string;
    version?: number;
  };
  wipMetaData?: {
    profitCenter: string;
  };
  clientType?: string;
  kyc?: IKYCObj;

  /* client POC to whom invoice is raised */
  poc?: IClientPOC[];

  allocation?: IAllocation[];

  propertyLocation?: string;

  secondParty?: string;

  anarockPOC?: {
    agentId: string;
    agent?: IUser;
  };
  wipArea?: {
    value: string;
    unit: string;
  };

  agreementDetails?: IAgrementDetails;

  approval?: IIRFApproval;

  profitCenter?: string;
  dealType?: string;
  groupName?: string;
  brandManager?: IUser;
  invoice?: {
    invoiceNo: string;
    dueDate: string;
    invoiceDate: string;
    invoiceURL: {
      url: string;
    };
    creditNoteNo: string;
    additionalInvoiceNo: string;
  };
  city?: ICity[];
  comment?: {
    text: string;
  };
  isEditable?: boolean;
  invoiceRequestType?: string;
  revenueRecognitionMonth?: string;
  wipType?: string;
  outstandingInvoice?: {
    invoiceCount: number;
    outstandingAmount: number;
    collectedAmount: number;
    totalAmount: number;
  };
  shippingAddress?: IClientAddress;
  creditRequestType?: string;
  creditNoteReason?: string;
  originalIrf?: IIRF;
}

export interface IDocumentUrl {
  url: string;
  alt: string;
  documentSideTag: string;
  _id?: string;
}
export interface IDocument {
  documentStructureId: string;
  documentURLs: IDocumentUrl[];
}

export interface IDocumentBody {
  irfId: string;
  body: IDocument[];
}

export interface IDeleteDocumentBody {
  irfId: string;
  body: {
    documentStructureId: string;
  };
}

export interface IDocuments {
  documentStructureId: string;
  documentStructure: {
    title: string;
    documentStructureId: string;
    allowedTypes: string[];
  };
  documentURLs: IDocumentsUrL[];
  disabled?: boolean;
}

export interface IGetSignedUrlBody {
  documentStructureId: string;
  documentSideTag: string;
  documentFileType: string;
}

export interface IGetSignedUrl {
  irfId: string;
  body: IGetSignedUrlBody[];
}

export interface ICreateInvoiceBody {
  irfId: string;
  body: {
    invoiceNo?: string | undefined;
    invoiceDate?: string;
    dueDate?: string;
    invoiceURL?: IImage;

    creditNoteNo?: string;
    creditNoteDate?: string;
    creditNoteURL?: IImage;

    additionalInvoiceNo?: string;
    additionalInvoiceDate?: string;
    additionalInvoiceURL?: IImage;
  };
}

export interface IIRFRequestBody {
  selectedFilters: IAnalyticsFilters;
  query?: string;
  params: paginationParams;
  sort?: { field: string | number; order: string };
  uid: string;
}

export interface ILegalDocument {
  url: string;
  alt: string;
  documentFileType: string;
  documentSideTag: string;
  key: string;
}

export interface IClientGST {
  _id?: string;
  clientType?: string;
  clientId?: string;
  address: IClientAddress;
  gst: {
    gstNumber: string;
    gstDocument?: ILegalDocument[];
  };
  kycNumber?: string;
}

export type IDocumentsResponse = GenericResponse<{
  irfDocuments: IDocuments[];
}>;
export type IGenericResponse = GenericResponse<void>;

export type IGSTDetailstResponse = GenericResponse<{
  gstDetail: IClientGST;
}>;

export type IClientGSTListResponse = GenericResponse<{
  gstDetails: IClientGST[];
}>;

export type IClientListResponse = GenericResponse<{ clients: IIRFClient[] }>;

export interface IUploadUrl {
  url: string;
  fields: IEnum;
}

export interface IClientListRequest {
  projectId: string;
  isConsulting?: boolean;
}

export interface IStatesResponse {
  name: string;
}

export type IGetSignedUrlResponse = GenericResponse<{
  documentURLs: [
    {
      documentStructureId: string;
      uploadURL: IUploadUrl;
      documentSideTag: string;
      documentFileType: string;
    }
  ];
}>;

export type IRFListResponse = GenericResponse<{
  irfList: IIRF[];
  paginationParams: IPaginationParam;
}>;

export interface ISuggestedIRF extends ISuggestedWIPInvoice {
  clientName: string;
  invoiceNo: string;
}
export type SuggestedIRFResponse = GenericResponse<{ irfs: ISuggestedIRF[] }>;

export type StatesResponse = GenericResponse<{
  states: IStatesResponse[];
}>;

export type IClientPOCListResponse = GenericResponse<{ pocs: IClientPOC[] }>;

export type IEditClientPOCResponse = GenericResponse<{ poc: IClientPOC }>;

export type ISortBody = { field: string | number; order: string };

export interface IFiltersRequest {
  selectedFilters: ISelectedFilters;
  defaultVal?: boolean;
  params?: paginationParams;
  sort?: ISortBody;
}
export type IEmptyResponse = GenericResponse<void>;

export type IEditIrfResponse = GenericResponse<{ irf: IIRF }>;

export interface ICreateCreditNoteBody {
  creditRequestType: string;
  creditNoteReason: string;
  irfId: string;
}

export const invoicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    invoicesFilters: builder.query<IFilterListResponse, IFiltersRequest>({
      query: ({ selectedFilters, defaultVal }) => ({
        url: `${INVOICES_URLS.INVOICE}${INVOICES_URLS.FILTERS}`,
        method: "POST",
        body: { selectedFilters, defaultVal },
      }),
    }),
    createIRF: builder.mutation<GenericResponse<{ irf?: IIRF }>, ICreateIRFBody>({
      query: (body) => ({
        url: `${INVOICES_URLS.INVOICE_REQUEST}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["IRFList"],
    }),
    deleteIRF: builder.mutation<IEmptyResponse, string>({
      query: (_id) => ({
        url: `${INVOICES_URLS.INVOICE_REQUEST}/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["IRFList"],
    }),
    editIRF: builder.mutation<IEditIrfResponse, IIRF>({
      query: (body) => ({
        url: `${INVOICES_URLS.INVOICE_REQUEST}/${body?._id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["IRFList", "IRFDetails"],
    }),
    getClientList: builder.query<IClientListResponse, IClientListRequest>({
      query: ({ projectId, isConsulting }) => ({
        url: `${isConsulting ? CONSULTING_WIP_URLS.WIP_CONSULTING_PROJECT : INVOICES_URLS.WIP_PROJECT}${projectId}${
          INVOICES_URLS.CLIENT
        }`,
        method: "GET",
      }),
    }),
    getIRFDetails: builder.query<GenericResponse<{ irf: IIRF }>, string>({
      query: (_id) => ({
        url: `${INVOICES_URLS.INVOICE_REQUEST}/${_id}`,
        method: "GET",
      }),
      providesTags: ["IRFDetails"],
    }),
    getInvoiceList: builder.query<IRFListResponse, IIRFRequestBody>({
      query: ({ selectedFilters, params, sort, query, uid }) => ({
        url: `${INVOICES_URLS.INVOICE}${INVOICES_URLS.LIST}`,
        method: "POST",
        body: { selectedFilters, sort, query, uid },
        params,
      }),
      providesTags: ["IRFList"],
    }),
    exportIRFExcel: builder.mutation<IBrochureResponse, string>({
      query: (irfID) => ({
        url: `${INVOICES_URLS.INVOICE}/${irfID}${INVOICES_URLS.EXPORT}`,
        method: "POST",
      }),
    }),
    uploadDocumentToUrl: builder.mutation<void, any>({
      query: ({ url, body }) => ({
        url,
        method: "POST",
        body,
        credentials: "omit",
      }),
      transformResponse: (_, meta: any) => {
        const res = meta?.response?.headers.get("location");
        return res;
      },
    }),
    updateIrfDocumentToUrl: builder.mutation<void, IDocumentBody>({
      query: ({ irfId, body }) => ({
        url: `${INVOICES_URLS.INVOICE}/${irfId}/${INVOICES_URLS.DOCUMENTS}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["IRFList"],
    }),
    getIrfDocuments: builder.query<IDocumentsResponse, { irfId: string; serviceCompletion: boolean }>({
      query: ({ irfId, serviceCompletion }) => ({
        url: `${INVOICES_URLS.INVOICE}/${irfId}/${INVOICES_URLS.DOCUMENTS}?serviceCompletion=${serviceCompletion}`,
        method: "GET",
      }),
      providesTags: ["IRFDetails", "IRFList"],
    }),
    deleteDocument: builder.mutation<IGenericResponse, IDeleteDocumentBody>({
      query: ({ irfId, body }) => ({
        url: `${INVOICES_URLS.INVOICE}/${irfId}/${INVOICES_URLS.DOCUMENTS}`,
        method: "PUT",
        body,
      }),
    }),
    getIrfSignedUrl: builder.mutation<IGetSignedUrlResponse, IGetSignedUrl>({
      query: ({ irfId, body }) => ({
        url: `${INVOICES_URLS.INVOICE}/${irfId}/${INVOICES_URLS.DOCUMENTS}/${INVOICES_URLS.UPLOAD_URL}`,
        method: "POST",
        body,
      }),
    }),
    getSignedUrlForInvoice: builder.query<IUploadImageUrlResponse, string>({
      query: (invoiceId) => ({
        url: `${INVOICES_URLS.WIP_INVOICE}/${invoiceId}/${INVOICES_URLS.UPLOAD_URL}`,
      }),
    }),

    createInvoice: builder.mutation<GenericResponse, ICreateInvoiceBody>({
      query: ({ irfId, body }) => ({
        url: `${INVOICES_URLS.INVOICE}/${irfId}/${INVOICES_URLS.CREATE_INVOICE}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["IRFList"],
    }),
    getGSTDetails: builder.query<IGSTDetailstResponse, string>({
      query: (gstNumber) => ({
        url: `${INVOICES_URLS.CLIENT_GST}${gstNumber}`,
      }),
    }),
    getIRFSearchSuggestions: builder.mutation<SuggestedIRFResponse, string>({
      query: (query) => ({
        url: INVOICES_URLS.INVOICE_SEARCH_AUTO_SUGGEST,
        method: "POST",
        body: {
          query,
          limit: 7,
        },
      }),
    }),
    irfCommentList: builder.query<GenericResponse<{ comments: IComment[] }>, string | null | undefined>({
      query: (projectId) => ({
        url: `/${INVOICES_URLS.COMMENT}${INVOICES_URLS.INVOICE_REQUEST_COMMENT}/${projectId}`,
        method: "GET",
      }),
      providesTags: ["IRFComment"],
    }),
    postCommentToIrf: builder.mutation<GenericResponse<void>, { projectId: string | null | undefined; text: string }>({
      query: ({ projectId, text }) => ({
        url: `/${INVOICES_URLS.COMMENT}${INVOICES_URLS.INVOICE_REQUEST_COMMENT}/${projectId}`,
        method: "POST",
        body: { text: text },
      }),
      invalidatesTags: ["IRFComment", "IRFList", "IRFTableWip"],
    }),
    getStates: builder.query<StatesResponse, void>({
      query: () => ({
        url: `${INVOICES_URLS.GEO_STATES_ALL}`,
        method: "GET",
      }),
    }),
    getClientPOCList: builder.query<IClientPOCListResponse, { clientId: string; clientType: string }>({
      query: ({ clientType, clientId }) => ({
        url: `${INVOICES_URLS.POC}/${clientType}/${clientId}`,
        method: "GET",
      }),
      providesTags: ["ClientPOCList"],
    }),
    addClientPOC: builder.mutation<GenericResponse, IClientPOC>({
      query: (body) => ({
        url: INVOICES_URLS.POC,
        body,
        method: "POST",
      }),
      invalidatesTags: ["ClientPOCList"],
    }),
    editClientPOC: builder.mutation<IEditClientPOCResponse, IClientPOC>({
      query: (body) => ({
        url: `${INVOICES_URLS.POC}/${body?._id}`,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["ClientPOCList"],
    }),
    exportInvoicePendingExcel: builder.mutation<IBrochureResponse, void>({
      query: () => ({
        url: `${INVOICES_URLS.INVOICE}${INVOICES_URLS.PENDING_LIST}${INVOICES_URLS.EXPORT}`,
        method: "POST",
      }),
    }),
    createCreditNote: builder.mutation<GenericResponse<{ irf?: IIRF }>, ICreateCreditNoteBody>({
      query: ({ irfId, creditNoteReason, creditRequestType }) => ({
        url: `${INVOICES_URLS.INVOICE}/${irfId}${INVOICES_URLS.CREDIT_NOTE}`,
        method: "POST",
        body: {
          creditNoteReason,
          creditRequestType,
        },
      }),
    }),
  }),
});

export const {
  useInvoicesFiltersQuery,
  useGetClientListQuery,
  useCreateIRFMutation,
  useEditIRFMutation,
  useGetIRFDetailsQuery,
  useDeleteIRFMutation,
  useGetInvoiceListQuery,
  useExportIRFExcelMutation,
  useUploadDocumentToUrlMutation,
  useUpdateIrfDocumentToUrlMutation,
  useGetIrfDocumentsQuery,
  useDeleteDocumentMutation,
  useGetIrfSignedUrlMutation,
  useLazyGetSignedUrlForInvoiceQuery,
  useCreateInvoiceMutation,
  useGetGSTDetailsQuery,
  useGetIRFSearchSuggestionsMutation,
  useIrfCommentListQuery,
  usePostCommentToIrfMutation,
  useGetStatesQuery,
  useGetClientPOCListQuery,
  useAddClientPOCMutation,
  useEditClientPOCMutation,
  useExportInvoicePendingExcelMutation,
  useCreateCreditNoteMutation,
} = invoicesApi;
