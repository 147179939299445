import { ArTree } from "@anarock/ar-common-react";
import { INestedFilterProps } from "components/filterBar/index.interface";
import React, { Key, useState } from "react";

import styles from "../../index.module.scss";
import ResetApplyButtonComponent from "../ResetApplyButtonComponent";

const NestedFilterComponent: React.FC<INestedFilterProps> = ({ options, onChange, value, setOpen, ...props }) => {
  const [filterArr, setFilterArr] = useState(value);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(value);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

  const onCheck = (checked: React.Key[] | { checked: Key[]; halfChecked: Key[] }) => {
    setFilterArr(checked);
    // onChange && onChange(checked);
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const handleApply = () => {
    setOpen && setOpen(false);
    onChange && onChange(filterArr);
  };

  const handleReset = () => {
    setFilterArr(props?.defaultValue);
    onChange && onChange(props?.defaultValue);
  };

  return (
    <>
      <ArTree
        className={styles.nestedFilter}
        autoExpandParent={autoExpandParent}
        expandedKeys={expandedKeys}
        checkable
        checkedKeys={filterArr}
        onCheck={onCheck}
        onExpand={onExpand}
        treeData={options}
      ></ArTree>
      <ResetApplyButtonComponent handleApply={handleApply} handleReset={handleReset} />
    </>
  );
};

export default NestedFilterComponent;
