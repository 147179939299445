import { ArButton, ArModal, ArTable } from "@anarock/ar-common-react";
import { ITableObj } from "app/services/analytics";
import { useGetIRFAllocationHistoryTableQuery } from "app/services/reports";
import { getValueOrDash, isNullOrUndefiendOrEmpty } from "app/utils/helperFunctions";
import { ShopFeature } from "components/shopDetailComponents/basicImageInfoCard";
import { TABLE_CONSTANTS } from "constants/helperStrings";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import styles from "../../../../analytics/wip/components/WIPInvoiceDetails/index.module.scss";
import AllocationHistoryModalProps from "./index.interface";

const IrfRevenueAllocationHistoryModal = ({ open, irf, closeModal, ...props }: AllocationHistoryModalProps) => {
  const { data, isFetching } = useGetIRFAllocationHistoryTableQuery(
    { irfProjectId: irf?.irfProjectId as string, profitCenter: irf?.profitCenter as string },
    {
      skip: !open || isNullOrUndefiendOrEmpty(irf?.irfProjectId) || isNullOrUndefiendOrEmpty(irf?.profitCenter),
    }
  );

  const getTableHeaders = () => {
    const headers = data?.data?.headers;

    if (!headers) return [];

    return Object.keys(headers).map((el, idx) => {
      if (el === "sno") {
        return {
          title: headers[el],
          dataIndex: el,
          key: el,
          width: 1,
          bordered: true,
          fixed: TABLE_CONSTANTS.LEFT,
          render: (text: number, record: ITableObj) => (
            <p className={record?.sno === "#" ? "bold" : undefined}>{getValueOrDash(text)}</p>
          ),
        };
      } else if (el === "allocatedRevenue" || el === "employeeCode") {
        return {
          title: headers[el],
          dataIndex: el,
          key: el,
          bordered: true,
          width: 5,
          render: (text: number, record: ITableObj) => (
            <p className={record?.sno === "#" ? "bold" : undefined}>{getValueOrDash(text)}</p>
          ),
        };
      }

      return {
        title: headers[el],
        dataIndex: el,
        key: el,
        bordered: true,
        width: 5,
        render: (text: string | number, record: ITableObj) => (
          <p className={record?.sno === "#" ? "bold" : undefined}>{getValueOrDash(text?.toLocaleString("en-IN"))}</p>
        ),
      };
    });
  };

  const rows = data?.data?.rows || [];

  return (
    <ArModal
      open={open}
      title={`${STRING_CONSTANTS.REVENUE_ALLOCATION} ${STRING_CONSTANTS.FOR} ${
        getValueOrDash(irf?.netRevenue) as string
      } ${STRING_CONSTANTS.FOR} ${irf?.clientName}`}
      width={946}
      footer={<ArButton onClick={closeModal}>{STRING_CONSTANTS.UNDERSTOOD}</ArButton>}
      onCancel={closeModal}
      {...props}
    >
      <div className={styles.invoiceDetails}>
        <h5 className="pb-16 pt-16 sub-hero-text">{STRING_CONSTANTS.REVENUE_DETAILS}</h5>
        <div className={styles.detailContainer}>
          <ShopFeature label={STRING_CONSTANTS.PROFIT_CENTER} value={getValueOrDash(irf?.profitCenter) as string} />
          <ShopFeature label={STRING_CONSTANTS.FEE_AMOUNT} value={getValueOrDash(irf?.feeAmount || 0) as string} />
          <ShopFeature label={STRING_CONSTANTS.NET_REVENUE} value={getValueOrDash(irf?.netRevenue) as string} />
          <ShopFeature label={STRING_CONSTANTS.POOLED_REVENUE} value={getValueOrDash(irf?.pooledRevenue) as string} />
          <ShopFeature label={STRING_CONSTANTS.ALLOCATED_REV} value={getValueOrDash(irf?.allocatedRevenue) as string} />
        </div>
        <h5 className="pb-16 pt-32 sub-hero-text">{`${STRING_CONSTANTS.ALLOCATION_DISTRIBUTION}`}</h5>
        <>
          <ArTable
            columns={getTableHeaders()}
            dataSource={rows}
            pagination={false}
            scroll={{
              y: 230,
              x: 1500,
              scrollToFirstRowOnChange: true,
            }}
            loading={isFetching}
          />
        </>
      </div>
    </ArModal>
  );
};

export default IrfRevenueAllocationHistoryModal;
