import { ArDropdown, ArFilterBar, ArSearchBox, ArTabs } from "@anarock/ar-common-react";
import colors from "@anarock/ar-common-react/dist/styles/partials/_colors.module.scss";
import { classNames } from "@anarock/ar-common-react/dist/utils/classNames";
import { CloseOutlined } from "@ant-design/icons";
import { ICarpetArea } from "app/services/createRequirmentApi";
import { IFilter, SelectedFiltersObject } from "app/services/listingPageApi";
import { isNullOrUndefiend, isNullOrUndefiendOrEmpty, isObjectNullOrUndefiendOrEmpty } from "app/utils/helperFunctions";
import { ReactComponent as Arrow } from "assets/images/small-arrow.svg";
import ShopSearch from "components/FilterSearch";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ResetApplyButtonComponent from "./components/ResetApplyButtonComponent";
import { IMoreFilterTabOptions, ListingFilterbarProps } from "./index.interface";
import { FilterPropsType } from "./index.interface";
import styles from "./index.module.scss";
import { Filter, FilterShimmer, MoreFilter } from "./partials";

export const ListingFilterBar: React.FC<ListingFilterbarProps> = ({
  filters,
  button,
  showSearch,
  moreFilters = [],
  searchPlaceholder = STRING_CONSTANTS.SEARCH_SHOP,
  SearchComponent = ShopSearch,
  loading,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFiltersObject>({
    ...JSON.parse(searchParams.get("filters") as string),
  });
  const [selectedMoreFilters, setSelectedMoreFilters] = React.useState<SelectedFiltersObject>({});

  const [modalOpen, setModalOpen] = useState(false);
  const [searchText, setSearchText] = useState((searchParams?.get("query") as string) || "");
  const [uid, setUid] = useState((searchParams?.get("uid") as string) || "");
  const [projectId, setProjectId] = useState((searchParams?.get("projectId") as string) || "");

  useEffect(() => {
    setSelectedFilters((prevFilters) => {
      const newFilters: SelectedFiltersObject = {};
      filters?.forEach((filter: IFilter) => {
        newFilters[filter.id] = filter.selected || filter?.defaultValue;
      });

      return { ...prevFilters, ...newFilters };
    });
  }, [filters]);

  useEffect(() => {
    if (moreFilters && moreFilters?.length === 0) {
      return;
    }
    setSelectedFilters((prevFilters) => {
      const newFilters: SelectedFiltersObject = {};
      moreFilters?.forEach((filter: IFilter) => {
        newFilters[filter.id] = filter.selected || filter?.defaultValue;
      });

      return { ...prevFilters, ...newFilters };
    });
  }, [moreFilters]);

  useEffect(() => {
    if (
      isObjectNullOrUndefiendOrEmpty(selectedFilters) &&
      isNullOrUndefiendOrEmpty(searchText) &&
      isNullOrUndefiendOrEmpty(uid)
    ) {
      return;
    }
    const redirectionString = `?filters=${JSON.stringify(selectedFilters)}${searchText ? `&query=${searchText}` : ""}${
      uid ? `&uid=${uid}` : ""
    }${projectId ? `&projectId=${projectId}` : ""}`;
    navigate({ search: redirectionString });
  }, [selectedFilters, searchText, uid, projectId]);

  useEffect(() => {
    setSearchText((searchParams?.get("query") as string) || "");
  }, [searchParams]);

  useEffect(() => {
    setUid((searchParams?.get("uid") as string) || "");
  }, [searchParams]);

  useEffect(() => {
    setProjectId((searchParams?.get("projectId") as string) || "");
  }, [searchParams]);

  const getFilterProps = (filter: IFilter, isMoreFilter: boolean) => {
    const FilterProps: FilterPropsType = {
      filterType: filter.type,
      filterProps: {
        defaultValue: filter?.defaultValue,
        value: filter.selected,
        placeholder: filter.title,
        hasAll: filter.hasAll,
        showSearch: filter?.showSearch,
        onChange: (value: string | string[] | ICarpetArea) => {
          if (isMoreFilter) {
            setSelectedMoreFilters((prev) => {
              return {
                ...prev,
                [filter.id]: value,
              };
            });
          } else {
            setSelectedFilters((prev) => {
              return {
                ...prev,
                [filter.id]: value,
              };
            });
          }
        },
        options: filter.data?.map((item) => {
          return {
            label: item.title,
            value: item.id,
            name: item.title,
            key: item.id,
            children: item?.children,
            title: item.title,
          };
        }),
      },
    };
    return FilterProps;
  };

  const genFilters = filters.map((filter: IFilter, index) => {
    return <Filter key={index} {...getFilterProps(filter, false)} />;
  });

  const SearchBar = () => {
    return (
      <ArSearchBox
        size={STRING_CONSTANTS.MIDDLE}
        placeholder={searchPlaceholder}
        onFocus={() => {
          setModalOpen(true);
        }}
        value={searchText}
        className={styles.filterSearchBar}
      />
    );
  };

  const moreFilterTabOptions = moreFilters?.map((filter: IFilter, idx: number) => {
    return {
      key: filter?.id,
      label: filter?.title,
      children: (
        <div>
          <MoreFilter key={idx} {...getFilterProps(filter, true)} />
        </div>
      ),
    };
  });

  const handleMoreFilterApply = () => {
    setSelectedFilters((prev) => {
      return {
        ...prev,
        ...selectedMoreFilters,
      };
    });
  };

  const handleMoreFilterReset = () => {
    let obj = {};
    moreFilters.forEach((filter: IFilter) => {
      obj = { ...obj, [filter.id]: filter?.defaultValue };
    });

    setSelectedFilters((prev) => {
      return {
        ...prev,
        ...obj,
      };
    });
  };

  const showMoreFilters = moreFilters?.length > 0 ? true : false;

  const filtersArr = [
    ...genFilters,
    showMoreFilters ? (
      <MoreFilterDropdown
        moreFilterTabOptions={moreFilterTabOptions}
        key={STRING_CONSTANTS.MORE_FILTERS}
        handleMoreFilterApply={handleMoreFilterApply}
        handleMoreFilterReset={handleMoreFilterReset}
      />
    ) : null,
    showSearch ? <SearchBar key={searchPlaceholder} /> : null,
  ];

  const handleUpdateSearchText = (val: string) => {
    setSearchText(val);
    setUid("");
    setProjectId("");
    setSelectedFilters({});
  };

  const handleSingleSelect = (val: string) => {
    setUid(val);
    setSearchText("");
    setProjectId("");
    setSelectedFilters({});
    setModalOpen(false);
  };
  if (loading) {
    return <FilterShimmer />;
  }

  return (
    <>
      {filters?.length ? (
        <>
          <ArFilterBar
            className={styles.filterBar}
            button={button}
            filtersList={filtersArr.filter((item) => !isNullOrUndefiend(item))}
          />
          <SearchComponent
            open={modalOpen}
            setModalOpen={(val: boolean) => setModalOpen(val)}
            updateText={handleUpdateSearchText}
            intialValue={searchText}
            handleSingleSelect={handleSingleSelect}
          />
        </>
      ) : null}
    </>
  );
};

const MoreFilterDropdown: React.FC<IMoreFilterTabOptions> = ({
  moreFilterTabOptions,
  handleMoreFilterApply,
  handleMoreFilterReset,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleApply = () => {
    setOpen(false);
    handleMoreFilterApply();
  };
  const items = [
    {
      key: "1",
      label: (
        <>
          <div className={styles.moreFiltersTitle}>
            <div className="dynamic-numbers">
              {STRING_CONSTANTS.MORE_FILTERS} ({moreFilterTabOptions?.length})
            </div>
            <CloseOutlined onClick={() => setOpen((prevVal) => !prevVal)} />
          </div>
          <ArTabs
            variant="anarock"
            items={moreFilterTabOptions}
            primaryColor={colors["primary-400"]}
            tabPosition="left"
            className={styles.tabWrapper}
          />
          <ResetApplyButtonComponent handleApply={handleApply} handleReset={handleMoreFilterReset} />
        </>
      ),
    },
  ];

  return (
    <>
      <ArDropdown
        open={open}
        menu={{ items }}
        trigger={["click"]}
        className={styles.dropdown}
        overlayClassName={"dropdownListMultiSelect"}
        onOpenChange={() => setOpen((prevVal) => !prevVal)}
      >
        <div className={styles.dropDownValue}>
          <p>{STRING_CONSTANTS.SELECT}</p> <Arrow {...classNames(styles.multiselectArrow, open && styles.rotate)} />
          <label className={styles.label}>{STRING_CONSTANTS.MORE_FILTERS}</label>
        </div>
      </ArDropdown>
    </>
  );
};
