import { ArBreadCrumb, ArPagination, ArTable } from "@anarock/ar-common-react";
import { ArBreadCrumbItem } from "@anarock/ar-common-react/dist/ui-components/ar-breadcrumb";
import ArTooltip from "@anarock/ar-common-react/dist/ui-components/ar-tooltip";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { ITableObj } from "app/services/analytics";
import { ISelectedFilters } from "app/services/listingPageApi";
import { useGetAllocationReportTableQuery } from "app/services/reports";
import { getValueOrDash } from "app/utils/helperFunctions";
import { TABLE_CONSTANTS } from "constants/helperStrings";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import styles from "../../../index.module.scss";
import { getStatusCellUI } from "./partials";

const pageLimit = 25;

const RevenueAllocationReportTable = () => {
  const [searchParams] = useSearchParams();

  const [selectedFilters, setSelectedFilters] = useState<ISelectedFilters>(
    JSON.parse(searchParams.get("filters") as string) || {}
  );

  const [pageNo, setPageNo] = useState(1);
  const [sortData, setSortData] = useState<{
    field: string | number;
    order: string;
  }>({
    field: "",
    order: "",
  });

  const { data, isFetching } = useGetAllocationReportTableQuery(
    {
      selectedFilters: selectedFilters,
      sort: sortData,
      params: {
        pageNo,
        pageLimit,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    setPageNo(1);
    setSelectedFilters(JSON.parse(searchParams.get("filters") as string));
  }, [searchParams]);

  const getTableHeaders = () => {
    const headers = data?.data?.headers;

    if (!headers) return [];

    return Object.keys(headers).map((el, idx) => {
      if (el === "sno") {
        return {
          title: headers[el],
          dataIndex: el,
          key: el,
          width: 20,
          bordered: true,
          fixed: TABLE_CONSTANTS.LEFT,
          render: (text: string | number, record: ITableObj, index: number) => (
            <>{text === "*" ? <div>-</div> : <div>{(pageNo - 1) * pageLimit + index}</div>}</>
          ),
        };
      } else if (el === "clientName") {
        return {
          title: headers[el],
          dataIndex: el,
          key: el,
          bordered: true,
          fixed: TABLE_CONSTANTS.LEFT,
          width: 60,
          render: (text: string | number, record: ITableObj) => (
            <ArTooltip heading="" customTitle={<div>{getValueOrDash(text?.toLocaleString("en-IN"))}</div>}>
              <div className={styles.tableCellEllipsis}>{getValueOrDash(text?.toLocaleString("en-IN"))}</div>
            </ArTooltip>
          ),
        };
      } else if (el === "netRevenue" || el === "pooledRevenue" || el === "revenueMonth" || el === "allocatedRevenue") {
        return {
          title: headers[el],
          dataIndex: el,
          key: el,
          bordered: true,
          sorter: true,
          width: 60,
          render: (text: string | number, record: ITableObj) => <p>{getValueOrDash(text?.toLocaleString("en-IN"))}</p>,
        };
      } else if (el === "allocationStatus") {
        return {
          title: headers[el],
          dataIndex: el,
          key: el,
          bordered: true,
          fixed: TABLE_CONSTANTS.RIGHT,
          width: 60,
          render: (text: string | number, record: ITableObj) => <p>{getStatusCellUI(text as string, record)}</p>,
        };
      }

      return {
        title: headers[el],
        dataIndex: el,
        key: el,
        bordered: true,
        width: 60,
        render: (text: string | number, record: ITableObj) => <p>{getValueOrDash(text?.toLocaleString("en-IN"))}</p>,
      };
    });
  };

  const dataSource = data?.data?.rows || [];
  const dataSummarySource = data?.data?.summaryRow || [];
  const columns = getTableHeaders() || [];

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any>
  ) => {
    const order = sorter?.order === "descend" ? "-1" : sorter?.order === "ascend" ? "1" : "";

    setPageNo(1);
    setSortData({ field: sorter?.field?.toString() || "", order });
  };

  return (
    <>
      <div>
        <ArBreadCrumb className={styles.breadCrumb}>
          <ArBreadCrumbItem className={styles.breadCrumbItem}>
            {STRING_CONSTANTS.REVENUE_ALLOCATION} (
            {`${getValueOrDash(data?.data?.rows?.length)}/${getValueOrDash(data?.data?.paginationParams?.count)}`})
          </ArBreadCrumbItem>
        </ArBreadCrumb>
      </div>
      <div className={styles.reportTableContainer}>
        <ArTable
          columns={columns}
          dataSource={[...dataSummarySource, ...dataSource]}
          pagination={false}
          loading={isFetching}
          scroll={{ x: columns.length * 200, y: window.innerHeight - 300 }}
          onChange={(pagination, filters, sorter) => handleChange(pagination, filters, sorter as SorterResult<any>)}
        />
      </div>
      <div className={styles.paginationContainer}>
        <ArPagination
          current={pageNo}
          onChange={(page) => {
            setPageNo(page);
          }}
          pageSize={pageLimit}
          total={data?.data?.paginationParams?.count || pageLimit}
          showSizeChanger={false}
        />
      </div>
    </>
  );
};

export default RevenueAllocationReportTable;
