// Need to use the React-specific entry point to allow generating React hooks
import { isFulfilled, isRejectedWithValue, Middleware, PayloadAction } from "@reduxjs/toolkit";
import { AnyAsyncThunk, RejectedWithValueActionFromAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import * as Sentry from "@sentry/react";
import { GENRIC_ERROR } from "constants/ErrorMessage";
import { logoutSuccess } from "features/authentication/authSlice";

export interface IFormError {
  param: string;
  reason: string;
}

export interface paginationParams {
  pageLimit: number;
  pageNo: number;
  summary?: boolean;
}

export interface IResponseError {
  errorCode: string;
  errorMessage: string;
  details?: {
    formErrors?: Array<IFormError>;
  };
}

export type SuccessResponse = {
  success: true;
  reason: null;
  data: unknown | null;
  errors: null;
};

export type FailureResponse = {
  success: false;
  reason: string;
  data: null;
  errors: Array<IResponseError>;
};

export type GenericResponse<DataType = unknown> = ({ data: DataType } & SuccessResponse) | FailureResponse;

export type IResponse = SuccessResponse | FailureResponse;

const baseQueryWithSentry: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    credentials: api.endpoint === "getAddressUsingCoordinates" ? undefined : "include",
    mode: "cors",
  });

  const result = await baseQuery(args, api, extraOptions);
  if (
    (result.error && result.error.status !== 401 && result.error.status !== 403) ||
    (result.data && !(result.data as IResponse).success)
  ) {
    Sentry.withScope((scope) => {
      scope.setExtra("args", args);
      scope.setFingerprint([api.endpoint]);
      Sentry.captureException(new Error(typeof args === "string" ? args : args.url));
    });
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithSentry,
  endpoints: () => ({}),
  tagTypes: [
    "auth",
    "RequirementDetailsCount",
    "OptionList",
    "MatchingProperties",
    "RequirementDetails",
    "Notifications",
    "Brands",
    "FilteredShops",
    "MultipleOptionsList",
    "SelfUpdate",
    "WipDetails",
    "WipList",
    "wipProjection",
    "sheet",
    "collectionProjection",
    "invoiceComment",
    "invoiceList",
    "Nudge",
    "wipComment",
    "Malls",
    "IRFDetails",
    "IRFList",
    "IRFComment",
    "IRFTableWip",
    "PaymentList",
    "PaymentComment",
    "ConsultingWipComment",
    "ConsultingWipList",
    "ConsultingWipProjection",
    "ConsultingWipDetails",
    "LandLordList",
    "ClientGSTList",
    "ClientPOCList",
    "IntelDataList",
    "IntelDataComment",
    "ClientKYCList",
    "AllocationReportTable",
  ],
});

export const handleErrorWithToast: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action: PayloadAction<GenericResponse> & RejectedWithValueActionFromAsyncThunk<AnyAsyncThunk>) => {
    if (isRejectedWithValue(action) && action.payload.status !== 401 && action.payload.status !== 403) {
      console.error(GENRIC_ERROR);
    } else if (isFulfilled(action)) {
      if (action.payload && !action.payload.success) {
        const reason = action.payload.reason;
        console.error(reason || GENRIC_ERROR);
      }
    }
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      console.error("auth eror");
      //show toast here as well that you need to login again
      // this will redirect user to login page by updating state
      dispatch(logoutSuccess());
    }
    return next(action);
  };
