import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const useFilters = <T>() => {
  const [searchParams] = useSearchParams();
  const [selectedFilters, setSelectedFilters] = useState<T>(JSON.parse(searchParams.get("filters") as string));

  useEffect(() => {
    setSelectedFilters({
      ...JSON.parse(searchParams.get("filters") as string),
    });
  }, [searchParams]);

  return [selectedFilters, setSelectedFilters] as const;
};

export const useFiltersWithQuery = <T>() => {
  const [searchParams] = useSearchParams();
  const [selectedFilters, setSelectedFilters] = useState<T>(JSON.parse(searchParams.get("filters") as string));

  const [query, setQuery] = useState<string>(searchParams.get("query") || "");
  const [uid, setUid] = useState<string>(searchParams.get("uid") || "");
  const [projectId, setProjectId] = useState<string>(searchParams.get("projectId") || "");

  const navigate = useNavigate();

  const updateQueryAndUid = useCallback(() => {
    setQuery((searchParams.get("query") as string) || "");
    setUid((searchParams.get("uid") as string) || "");
    setProjectId((searchParams.get("projectId") as string) || "");
  }, [searchParams]);

  const handleResetSearch = () => {
    navigate({ search: "filters=" + JSON.stringify(selectedFilters) });
  };

  useEffect(() => {
    setSelectedFilters({
      ...JSON.parse(searchParams.get("filters") as string),
    });
    setQuery((searchParams.get("query") as string) || "");
    setUid((searchParams.get("uid") as string) || "");
    setProjectId((searchParams.get("projectId") as string) || "");
  }, [searchParams]);

  return {
    selectedFilters,
    setSelectedFilters,
    query,
    uid,
    updateQueryAndUid,
    setQuery,
    setUid,
    handleResetSearch,
    projectId,
    setProjectId,
  } as const;
};
