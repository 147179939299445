import ArPopoverSearch from "@anarock/ar-common-react/dist/ui-components/ar-popover-search";
import { useGetWIPSearchSuggestionsMutation } from "app/services/analytics";
import { useGetInvoiceSearchSuggestionsMutation } from "app/services/collections";
import { useGetIRFSearchSuggestionsMutation } from "app/services/invoices";
import { IFiltersList } from "app/services/listingPageApi";
import { useGetPaymentsSuggestionsMutation } from "app/services/payments";
import { useGetSearchSuggestionsMutation } from "app/services/shopListingApi";
import { debounce } from "app/utils/helperFunctions";
import { useFilters } from "app/utils/useFiltersHook";
import { ReactComponent as SearchIcon } from "assets/images/searchIcon.svg";
import { useToastService } from "components/toastService";
import { INVOICE_APPROVED_TAB } from "constants/index";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import FilterSearchProps from "./index.interface";
import styles from "./index.module.scss";
import { DropdownRender } from "./partials";

const ShopSearch = ({ open, setModalOpen, updateText, intialValue }: FilterSearchProps) => {
  const [fetchSuggestedShops, { data, isLoading }] = useGetSearchSuggestionsMutation();
  const { errorToast } = useToastService();
  const [searchText, setSearchText] = useState(intialValue);

  const updateSearchText: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    setSearchText(event?.target?.value);
  };

  const renderItem = (title: string, highlight: string, value: string) => ({
    value,
    label: (
      <div className={styles.renderSearchItem}>
        <SearchIcon />
        <div className={styles.textContainer}>
          <p className={styles.title}>{title}</p>
          <p className={styles.subHeading} dangerouslySetInnerHTML={{ __html: highlight }}></p>
        </div>
      </div>
    ),
  });

  const handleViewAllClick = () => {
    updateText(searchText || "");
    setModalOpen(false);
  };

  const openShopDetails = (val: string) => {
    window.open(`/properties/${val}`, "_blank");
  };

  const enterkeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    event?.preventDefault();
    handleViewAllClick();
  };

  const fetchShops = useCallback(
    async (text: string) => {
      if (text.length < 3) {
        return;
      }
      try {
        await fetchSuggestedShops(text).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    },
    [errorToast, fetchSuggestedShops]
  );

  const debouncedGetResults = useMemo(() => debounce(fetchShops, 300), [fetchShops]);

  useEffect(() => {
    if (searchText && open) {
      debouncedGetResults(searchText);
    }
  }, [searchText, open, debouncedGetResults]);

  return (
    <div>
      <ArPopoverSearch
        modalProps={{ open, onCancel: () => setModalOpen(false) }}
        autoCompleteProps={{
          dropdownRender: (menu) =>
            DropdownRender(
              menu,
              handleViewAllClick,
              STRING_CONSTANTS.SHOPS,
              Boolean(!data?.data?.shopRes || (data?.data?.shopRes?.length === 0 && !isLoading))
            ),
          options: searchText
            ? data?.data?.shopRes?.map(({ shopNumber, address, highlight, _id }) =>
                renderItem(`${shopNumber}, ${address}`, highlight[0], _id)
              ) || []
            : [],
          value: searchText,
          onSelect: openShopDetails,
          allowClear: false,
        }}
        inputProps={{
          placeholder: STRING_CONSTANTS.SEARCH_SHOP_PLACEHOLDER,
          onFocus: updateSearchText,
          onChange: updateSearchText,
          loading: isLoading,
          className: styles.searchBox,
          allowClear: true,
          onPressEnter: enterkeyPress,
        }}
      />
    </div>
  );
};

export const WIPSearch = ({ open, setModalOpen, updateText, intialValue, handleSingleSelect }: FilterSearchProps) => {
  const [fetchSuggestedWIP, { data, isLoading }] = useGetWIPSearchSuggestionsMutation();
  const { errorToast } = useToastService();
  const [searchText, setSearchText] = useState(intialValue);

  const location = useLocation();
  const isConsulting = location.pathname.includes(APP_ROUTES.CONSULTING);

  const updateSearchText: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    setSearchText(event?.target?.value);
  };

  const renderItem = (title: string, highlight: TrustedHTML, value: string) => ({
    value,
    label: (
      <div className={styles.renderSearchItem}>
        <SearchIcon />
        <div className={styles.textContainer}>
          <p>
            <span className={styles.title}>{STRING_CONSTANTS.PROJECT_ID}: </span>
            {title}
          </p>
          <p className={styles.subHeading} dangerouslySetInnerHTML={{ __html: highlight }}></p>
        </div>
      </div>
    ),
  });

  const handleViewAllClick = () => {
    updateText(searchText || "");
    setModalOpen(false);
  };

  const handleSelect = (val: string) => {
    handleSingleSelect(val);
  };

  const enterkeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    event?.preventDefault();
    handleViewAllClick();
  };

  const fetchWIPS = useCallback(
    async (query: string) => {
      if (query?.length < 3) {
        return;
      }
      try {
        await fetchSuggestedWIP({ query, isConsulting }).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    },
    [errorToast, fetchSuggestedWIP, isConsulting]
  );

  const debouncedGetResults = useMemo(() => debounce(fetchWIPS, 300), [fetchWIPS]);

  useEffect(() => {
    if (searchText && open) {
      debouncedGetResults(searchText);
    }
  }, [searchText, open, debouncedGetResults]);

  return (
    <div>
      <ArPopoverSearch
        modalProps={{ open, onCancel: () => setModalOpen(false) }}
        autoCompleteProps={{
          dropdownRender: (menu) =>
            DropdownRender(
              menu,
              handleViewAllClick,
              STRING_CONSTANTS.WIP,
              Boolean(!data?.data?.wips || (data?.data?.wips?.length === 0 && !isLoading))
            ),
          options:
            searchText && searchText?.length > 2
              ? data?.data?.wips?.map(({ projectId, highlight, _id }) =>
                  renderItem(`${projectId}`, highlight[0], _id)
                ) || []
              : [],
          value: searchText,
          onSelect: handleSelect,
          allowClear: false,
        }}
        inputProps={{
          placeholder: STRING_CONSTANTS.SEARCH_WIP_PARAM,
          onFocus: updateSearchText,
          onChange: updateSearchText,
          loading: isLoading,
          className: styles.searchBox,
          allowClear: true,
          onPressEnter: enterkeyPress,
        }}
      />
    </div>
  );
};

export const InvoiceSearch = ({
  open,
  setModalOpen,
  updateText,
  intialValue,
  handleSingleSelect,
}: FilterSearchProps) => {
  const [fetchSuggestedInvoice, { data, isLoading }] = useGetInvoiceSearchSuggestionsMutation();
  const { errorToast } = useToastService();
  const [searchText, setSearchText] = useState(intialValue);

  const updateSearchText: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    setSearchText(event?.target?.value);
  };

  const renderItem = (title: string, highlight: TrustedHTML, value: string) => ({
    value,
    label: (
      <div className={styles.renderSearchItem}>
        <SearchIcon />
        <div className={styles.textContainer}>
          <p>
            <span className={styles.title}>{STRING_CONSTANTS.INVOICE_NO} : </span>
            {title}
          </p>
          <p className={styles.subHeading} dangerouslySetInnerHTML={{ __html: highlight }}></p>
        </div>
      </div>
    ),
  });

  const handleViewAllClick = () => {
    updateText(searchText || "");
    setModalOpen(false);
  };

  const handleSelect = (val: string) => {
    handleSingleSelect(val);
  };

  const enterkeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    event?.preventDefault();
    handleViewAllClick();
  };

  const fetchWIPS = useCallback(
    async (text: string) => {
      if (text.length < 3) {
        return;
      }
      try {
        await fetchSuggestedInvoice(text).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    },
    [errorToast, fetchSuggestedInvoice]
  );

  const debouncedGetResults = useMemo(() => debounce(fetchWIPS, 300), [fetchWIPS]);

  useEffect(() => {
    if (searchText && open) {
      debouncedGetResults(searchText);
    }
  }, [searchText, open, debouncedGetResults]);

  return (
    <div>
      <ArPopoverSearch
        modalProps={{ open, onCancel: () => setModalOpen(false) }}
        autoCompleteProps={{
          dropdownRender: (menu) =>
            DropdownRender(
              menu,
              handleViewAllClick,
              STRING_CONSTANTS.DEALS,
              Boolean(!data?.data?.invoices || (data?.data?.invoices?.length === 0 && !isLoading))
            ),
          options:
            searchText && searchText?.length > 2
              ? data?.data?.invoices?.map(({ invoiceNo, highlight, _id }) =>
                  renderItem(`${invoiceNo}`, highlight[0], _id)
                ) || []
              : [],
          value: searchText,
          onSelect: handleSelect,
          allowClear: false,
        }}
        inputProps={{
          placeholder: STRING_CONSTANTS.SEARCH_INVOICE_PARAM,
          onFocus: updateSearchText,
          onChange: updateSearchText,
          loading: isLoading,
          className: styles.searchBox,
          allowClear: true,
          onPressEnter: enterkeyPress,
        }}
      />
    </div>
  );
};

export const IRFSearch = ({ open, setModalOpen, updateText, intialValue, handleSingleSelect }: FilterSearchProps) => {
  const [fetchSuggestedIRF, { data, isLoading }] = useGetIRFSearchSuggestionsMutation();
  const { errorToast } = useToastService();
  const [searchText, setSearchText] = useState(intialValue);
  const [selectedFilters] = useFilters<IFiltersList>();

  const isApprovedTab = selectedFilters?.INVOICE_APPROVED_TAB === INVOICE_APPROVED_TAB.APPROVED_GENERATED;

  const updateSearchText: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    setSearchText(event?.target?.value);
  };

  const renderItem = (title: string, highlight: TrustedHTML, value: string) => ({
    value,
    label: (
      <div className={styles.renderSearchItem}>
        <SearchIcon />
        <div className={styles.textContainer}>
          <p>
            <span className={styles.title}>{STRING_CONSTANTS.PROJECT_ID} : </span>
            {title}
          </p>
          <p className={styles.subHeading} dangerouslySetInnerHTML={{ __html: highlight }}></p>
        </div>
      </div>
    ),
  });

  const handleViewAllClick = () => {
    updateText(searchText || "");
    setModalOpen(false);
  };

  const handleSelect = (val: string) => {
    handleSingleSelect(val);
  };

  const enterkeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    event?.preventDefault();
    handleViewAllClick();
  };

  const fetchIRFS = useCallback(
    async (text: string) => {
      if (text.length < 3) {
        return;
      }
      try {
        await fetchSuggestedIRF(text).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    },
    [errorToast, fetchSuggestedIRF]
  );

  const debouncedGetResults = useMemo(() => debounce(fetchIRFS, 300), [fetchIRFS]);

  useEffect(() => {
    if (searchText && open) {
      debouncedGetResults(searchText);
    }
  }, [searchText, open, debouncedGetResults]);

  return (
    <div>
      <ArPopoverSearch
        modalProps={{ open, onCancel: () => setModalOpen(false) }}
        autoCompleteProps={{
          dropdownRender: (menu) =>
            DropdownRender(
              menu,
              handleViewAllClick,
              STRING_CONSTANTS.INVOICES,
              Boolean(!data?.data?.irfs || (data?.data?.irfs?.length === 0 && !isLoading))
            ),
          options:
            searchText && searchText?.length > 2
              ? data?.data?.irfs?.map(({ projectId, highlight, _id }) =>
                  renderItem(`${projectId}`, highlight[0], _id)
                ) || []
              : [],
          value: searchText,
          onSelect: handleSelect,
          allowClear: false,
        }}
        inputProps={{
          placeholder: isApprovedTab ? STRING_CONSTANTS.SEARCH_IRF_APPROVED : STRING_CONSTANTS.SEARCH_IRF_PENDING,
          onFocus: updateSearchText,
          onChange: updateSearchText,
          loading: isLoading,
          className: styles.searchBox,
          allowClear: true,
          onPressEnter: enterkeyPress,
        }}
      />
    </div>
  );
};

export const ClaimPaymentsSearch = ({
  open,
  setModalOpen,
  updateText,
  intialValue,
  handleSingleSelect,
}: FilterSearchProps) => {
  const [fetchSuggestedPaymentClaims, { data, isLoading }] = useGetPaymentsSuggestionsMutation();
  const { errorToast } = useToastService();
  const [searchText, setSearchText] = useState(intialValue);

  const updateSearchText: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    setSearchText(event?.target?.value);
  };
  const renderItem = (title: string, highlight: TrustedHTML, value: string) => ({
    value,
    label: (
      <div className={styles.renderSearchItem}>
        <SearchIcon />
        <div className={styles.textContainer}>
          <p>
            <span className={styles.title}>{FORM_FIELDS.CHEQUE_NO} : </span>
            {title}
          </p>
          <p className={styles.subHeading} dangerouslySetInnerHTML={{ __html: highlight }}></p>
        </div>
      </div>
    ),
  });

  const handleViewAllClick = () => {
    updateText(searchText || "");
    setModalOpen(false);
  };

  const handleSelect = (val: string) => {
    handleSingleSelect(val);
  };

  const enterkeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    event?.preventDefault();
    handleViewAllClick();
  };

  const fetchPaymentClaims = useCallback(
    async (text: string) => {
      if (text.length < 3) {
        return;
      }
      try {
        await fetchSuggestedPaymentClaims(text).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    },
    [errorToast, fetchSuggestedPaymentClaims]
  );

  const debouncedGetResults = useMemo(() => debounce(fetchPaymentClaims, 300), [fetchPaymentClaims]);

  useEffect(() => {
    if (searchText && open) {
      debouncedGetResults(searchText);
    }
  }, [searchText, open, debouncedGetResults]);

  return (
    <div>
      <ArPopoverSearch
        modalProps={{ open, onCancel: () => setModalOpen(false) }}
        autoCompleteProps={{
          dropdownRender: (menu) =>
            DropdownRender(
              menu,
              handleViewAllClick,
              STRING_CONSTANTS.PAYMENTS_CLAIM,
              Boolean(!data?.data?.paymentClaims || (data?.data?.paymentClaims?.length === 0 && !isLoading))
            ),
          options:
            searchText && searchText?.length > 2
              ? data?.data?.paymentClaims?.map(({ paymentId, highlight, _id }) =>
                  renderItem(`${paymentId}`, highlight[0], _id)
                ) || []
              : [],
          value: searchText,
          onSelect: handleSelect,
          allowClear: false,
        }}
        inputProps={{
          placeholder: STRING_CONSTANTS.SEARCH_PAYMENTS_PLACEHOLDER,
          onFocus: updateSearchText,
          onChange: updateSearchText,
          loading: isLoading,
          className: styles.searchBox,
          allowClear: true,
          onPressEnter: enterkeyPress,
        }}
      />
    </div>
  );
};
export default ShopSearch;
