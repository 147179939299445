import { isNullOrUndefiend } from "app/utils/helperFunctions";
import { ANALYTICS_URLS } from "constants/ApiUrls";
import { CollectionTableType } from "features/analytics/wip/components/CollectionModalTable/index.interface";
import { IUser } from "features/authentication/authSlice";

import { IAnalyticsFilters, IAnalyticsSortRequest, IAnalyticsTable, ISuggestedWIP } from "./analytics";
import { api, GenericResponse } from "./api";
import { ICity } from "./listingPageApi";
import { IPaginationParam } from "./notificationsApi";

export interface IInvoiceMonthlyProjection {
  month: string;
  monthKey: string;
  projection: {
    current: number;
    flash: number;
    tentative: number;
    tentativeFlash: number;
  };
  collected: number;
}

export interface IComment {
  _id: string;
  text: string;
  invoiceNo: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  tag: string;
  status?: {
    state: string;
  };
  user: IUser;
}

export interface IInvoice {
  projectId: string;
  invoiceNo: string;
  invoiceDate: string;
  status: { state: string };
  client: { name: string; type: string };
  dealDetails: string;
  city: ICity[];
  brandManager: { name: string };
  monthlyProjectionDetails: IInvoiceMonthlyProjection[];
  monthlyProjection: {
    [key: string]: IInvoiceMonthlyProjection;
  };
  collected: number;
  current: number;
  tentative: number;
  expectedCollectionDate: string;
  dueDate: string;
  netRevenue?: number;
  invoiceValue?: number;
  outstanding: number;
  comment: {
    text: string;
  };
  wipMetaData?: {
    profitCenter: string;
  };
  dealType?: string;
  isEditable?: string;
  landLordType: string;
  wipType: string;
  creditNoteNo?: string;
  additionalInvoiceNo?: string;
  invoiceRequestType?: string;
  creditNoteType?: string;
}

export interface IFreezeProjectionPreview {
  current: number;
  tentative: number;
  region: { groupName: string };
}

export interface ICollectionAnalyticsFiltersRequest {
  selectedFilters: IAnalyticsFilters;
}
export interface ICollectionAnalyticsViewDetailsTableRequest {
  selectedFilters: IAnalyticsFilters;
  type?: CollectionTableType;
}

export interface ICollectionRegion {
  outstanding: number;
  collected: number;
  collectedWithoutGst: number;
  entity: { name: string };
}

export interface ICollectionAgent {
  outstanding: number;
  collected: number;
  collectedWithoutGst: number;
  region: { groupName: string };
  brandManager: { name: string };
}

export interface ICollectionDebtor {
  levels: {
    [key: string]: {
      invoiceAmount: number;
    };
  };
  entity: { name: string };
}

export interface IBadDebtAnalytic {
  entity: {
    type: string;
    name: string;
  };
  badDebtValue: number;
  netRevenue: number;
}

export type ICollectionInvoiceListResponse = GenericResponse<{
  invoices: IInvoice[];
  paginationParams: IPaginationParam;
}>;

export type IFreezeProjectionPreviewResponse = GenericResponse<{
  regionalProjections: IFreezeProjectionPreview[];
}>;

export type ICollectionRegionResponse = GenericResponse<{
  geoAnalytics: ICollectionRegion[];
}>;

export type ICollectionAgentResponse = GenericResponse<{
  userAnalytics: ICollectionAgent[];
}>;

export type ICollectionDebtorResponse = GenericResponse<{
  debtorAnalytics: ICollectionDebtor[];
  levels: {
    [key: string]: string;
  };
}>;

export type ICollectionAnalyticsBedDebtResponse = GenericResponse<{
  badDebtAnalytics: {
    Issues: IBadDebtAnalytic[];
    Legal: IBadDebtAnalytic[];
    "Write-off": IBadDebtAnalytic[];
  };
}>;
export interface IInvoiceBody {
  dealDetails?: string;
  expectedCollectionDate?: string;
  status?: {
    state: string;
    comment: string;
  };
}

export type IFreezeCollectionProjectionResponse = GenericResponse<{
  isFrozen: boolean;
  nonProjectedInvoiceCount: number;
  totalFreezableInvoiceCount: number;
}>;

export interface ISuggestedWIPInvoice extends ISuggestedWIP {
  clientName: string;
  invoiceNo: string;
}

export type SuggestedInvoiceResponse = GenericResponse<{ invoices: ISuggestedWIPInvoice[] }>;

export const collectionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    freezeCollectionProjection: builder.mutation<IFreezeCollectionProjectionResponse, void>({
      query: () => ({
        url: `/wip/invoice/freeze`,
        method: "POST",
      }),
      invalidatesTags: ["collectionProjection"],
    }),
    getCollectionFreezeStatus: builder.query<GenericResponse<{ isFrozen: boolean }>, void>({
      query: () => ({
        url: `/wip/invoice/freeze-status`,
        method: "GET",
      }),
      providesTags: ["collectionProjection"],
    }),
    invoiceList: builder.query<ICollectionInvoiceListResponse, IAnalyticsSortRequest>({
      query: ({ filters, params, sort, query, uid, projectId }) => ({
        url: ANALYTICS_URLS.INVOICE_LIST,
        method: "POST",
        body: { selectedFilters: filters, sort, query, uid, projectId },
        params,
      }),
      providesTags: ["invoiceList"],
    }),
    commentList: builder.query<GenericResponse<{ comments: IComment[] }>, string>({
      query: (invoiceNo) => ({
        url: `/comment/invoice/${invoiceNo}`,
        method: "GET",
      }),
      providesTags: ["invoiceComment"],
    }),
    postCommentToInvoice: builder.mutation<GenericResponse<void>, { invoiceNo: string; comment: string }>({
      query: ({ invoiceNo, comment }) => ({
        url: `/comment/invoice/${invoiceNo}`,
        method: "POST",
        body: { text: comment },
      }),
      invalidatesTags: ["invoiceComment", "invoiceList"],
    }),
    updateInvoiceData: builder.mutation<GenericResponse<void>, { invoiceNo: string; body: IInvoiceBody }>({
      query: ({ invoiceNo, body }) => ({
        url: `wip/invoice/${invoiceNo}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["invoiceList", "invoiceComment"],
    }),
    getCollectionHistoryTable: builder.query<GenericResponse<any>, string>({
      query: (projectId) => ({
        url: `wip/invoice/${projectId}/collections `,
        method: "GET",
      }),
    }),
    getFreezeProjectionPreview: builder.query<GenericResponse<any>, void>({
      query: () => ({
        url: `/wip/invoice/freeze-preview`,
        method: "GET",
      }),
    }),
    getCollectionRegionData: builder.query<ICollectionRegionResponse, ICollectionAnalyticsFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `/analytics/wip/invoice/geo`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    getCollectionAgentData: builder.query<ICollectionAgentResponse, ICollectionAnalyticsFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `/analytics/wip/invoice/users`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    getCollectionDebtorData: builder.query<ICollectionDebtorResponse, ICollectionAnalyticsFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `/analytics/wip/invoice/debtor`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    getCollectionBadDebtData: builder.query<ICollectionAnalyticsBedDebtResponse, ICollectionAnalyticsFiltersRequest>({
      query: ({ selectedFilters }) => ({
        url: `/analytics/wip/invoice/bad-debt`,
        method: "POST",
        body: { selectedFilters },
      }),
    }),
    viewDetailsTable: builder.query<
      GenericResponse<{ table: IAnalyticsTable }>,
      ICollectionAnalyticsViewDetailsTableRequest
    >({
      query: ({ type, selectedFilters }) => ({
        url: `/analytics/wip/invoice/${type}/table`,
        method: "POST",
        body: { selectedFilters },
        skip: isNullOrUndefiend(type),
      }),
    }),
    getInvoiceSearchSuggestions: builder.mutation<SuggestedInvoiceResponse, string>({
      query: (query) => ({
        url: ANALYTICS_URLS.INVOICE_AUTO_SUGGEST,
        method: "POST",
        body: {
          query,
          limit: 7,
        },
      }),
    }),
  }),
});

export const {
  useGetCollectionFreezeStatusQuery,
  useFreezeCollectionProjectionMutation,
  useInvoiceListQuery,
  useCommentListQuery,
  usePostCommentToInvoiceMutation,
  useUpdateInvoiceDataMutation,
  useGetCollectionHistoryTableQuery,
  useGetFreezeProjectionPreviewQuery,
  useGetCollectionAgentDataQuery,
  useGetCollectionRegionDataQuery,
  useGetCollectionDebtorDataQuery,
  useGetCollectionBadDebtDataQuery,
  useViewDetailsTableQuery,
  useGetInvoiceSearchSuggestionsMutation,
} = collectionsApi;
