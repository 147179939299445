import { ArButton, ButtonTypes } from "@anarock/ar-common-react";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import styles from "./index.module.scss";

export const DropdownRender = (
  menu: React.ReactNode,
  handleViewAllClick: () => void,
  matchingText: string,
  hideViewAll: boolean
) => {
  return (
    <div className={styles.searchDropDown}>
      <span className={styles.heading}>
        <span>{`${STRING_CONSTANTS.MATCHING} ${matchingText}`}</span>
        {hideViewAll ? null : (
          <ArButton type={ButtonTypes.Link} onClick={handleViewAllClick} tabIndex={-1}>
            {STRING_CONSTANTS.VIEW_ALL}
          </ArButton>
        )}
      </span>
      <div>{menu}</div>
    </div>
  );
};
