import { ArDropdown, ArMultiSelectProps, ArRadioSelect, ArSelectBox, ArSelectBoxProps } from "@anarock/ar-common-react";
import { optionProps } from "@anarock/ar-common-react/dist/ui-components/ar-checkbox/index.interface";
import ArTooltip from "@anarock/ar-common-react/dist/ui-components/ar-tooltip";
import { classNames } from "@anarock/ar-common-react/dist/utils/classNames";
import { Skeleton } from "antd";
import { getCompressedString } from "app/utils/helperFunctions";
import { ReactComponent as Arrow } from "assets/images/small-arrow.svg";
import { FILTER_TYPES } from "constants/ListingEnums";
import { monthsArr } from "constants/months";
import { STRING_CONSTANTS } from "constants/StringConstants";
import moment from "moment";
import React, { useEffect, useMemo } from "react";

import DurationFilterComponent from "./components/DurationFilterComponent";
import MinMaxFilterComponent from "./components/MinMaxFilterComponent";
import MultiSelectFilterComponent from "./components/MultiSelectFilterComponent";
import NestedFilterComponent from "./components/NestedFilterComponent";
import {
  FilterPropsType,
  IDurationFilterProps,
  IDurationOptions,
  INestedFilterProps,
  MinMaxFilterProps,
} from "./index.interface";
import styles from "./index.module.scss";

export const getLabelforValues = (value: string[], options: optionProps[]) => {
  if (value?.length === 0 || value?.length === options?.length) return [STRING_CONSTANTS.ALL_TEXT];
  return options.filter((option) => value?.includes(option.value)).map((option) => option.label as string);
};

export const sortSelectedValues = (options: optionProps[], value: string[]) => {
  if (value?.length === 0) return options;
  const optionsArr: optionProps[] = [];
  options.forEach((el) => {
    if (value?.includes(el.value)) {
      optionsArr.push(el);
    }
  });
  options.forEach((el) => {
    if (!value.includes(el.value)) {
      optionsArr.push(el);
    }
  });

  return optionsArr;
};

export const MinMaxFilter: React.FC<MinMaxFilterProps> = ({
  defaultValue: { min, max },
  value,
  onChange,
  ...props
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [rangeValue, setRangeValue] = React.useState<[number, number]>(
    value?.min && value?.max ? [value.min, value.max] : [min, max]
  );

  const items = [
    {
      key: "1",
      label: (
        <div className={styles.selectCarpetArea}>
          <p className={`dynamic-numbers ${styles.carpetHeaderPadding}`}>
            {STRING_CONSTANTS.SELECT} {props.placeholder}
          </p>
          <MinMaxFilterComponent
            min={min}
            max={max}
            rangeValue={rangeValue}
            setRangeValue={setRangeValue}
            value={value}
            onChange={onChange}
            setOpen={setOpen}
            {...props}
          />
        </div>
      ),
    },
  ];
  return (
    <ArDropdown
      open={open}
      menu={{ items }}
      trigger={["click"]}
      className={`${styles.dropdown} ${min !== value?.min || max !== value?.max ? styles.selected : null}`}
      overlayClassName={"dropdownListMultiSelect"}
      onOpenChange={() => setOpen((prevVal) => !prevVal)}
    >
      <div className={styles.dropDownValue}>
        <ArTooltip
          heading=""
          customTitle={
            rangeValue && rangeValue[0].toLocaleString("en-IN") + " - " + rangeValue[1].toLocaleString("en-IN")
          }
          placement="bottom"
        >
          <p className={`textEllipsis ${styles.filterEllipsisWidth}`}>
            {rangeValue && rangeValue[0].toLocaleString("en-IN") + " - " + rangeValue[1].toLocaleString("en-IN")}
          </p>
        </ArTooltip>
        <Arrow {...classNames(styles.multiselectArrow, open && styles.rotate)} />
        <label className={styles.label}>{props.placeholder}</label>
      </div>
    </ArDropdown>
  );
};

export const SingleSelect: React.FC<ArSelectBoxProps> = ({ options, onChange, value, placeholder, ...props }) => {
  const searchOptions = options?.map((option) => {
    return {
      label: option.label,
      value: option.label as string,
    };
  });
  const handleChange = (value: string) => {
    const selectedOption = options?.find((option) => option.label === value);
    onChange && onChange(selectedOption?.value, selectedOption as optionProps);
  };
  return (
    <ArSelectBox
      className={`${styles.singleSelectFilter} ${value ? styles.singleSelectSelected : null}`}
      showSearch={true}
      label={placeholder as string}
      {...props}
      options={searchOptions}
      onChange={handleChange}
      value={options?.find((option) => option.value === value)?.label}
    />
  );
};

export const MultiSelectFilter: React.FC<ArMultiSelectProps> = ({ options, onChange, ...props }) => {
  const [selectedItemsLabel, setSelectedItemsLabel] = React.useState<string[]>(
    getLabelforValues(props?.defaultValue as string[], options)
  );
  const [open, setOpen] = React.useState<boolean>(false);
  useEffect(() => {
    setSelectedItemsLabel(getLabelforValues((props.value || []) as string[], options));
  }, [props.value]);

  const sortedOptions = useMemo(() => {
    return sortSelectedValues(options, (props.value || []) as string[]);
  }, [options, props.value]);

  const items = [
    {
      key: "1",
      label: (
        <MultiSelectFilterComponent
          options={sortedOptions}
          onChange={onChange}
          setOpen={setOpen}
          {...props}
        ></MultiSelectFilterComponent>
      ),
    },
  ];

  return (
    <ArDropdown
      open={open}
      menu={{ items }}
      trigger={["click"]}
      className={`${styles.dropdown} ${props?.value?.length ? styles.selected : null}`}
      overlayClassName={"dropdownListMultiSelect"}
      onOpenChange={() => setOpen((prevVal) => !prevVal)}
      destroyPopupOnHide={true}
    >
      <div className={styles.dropDownValue}>
        <ArTooltip
          heading=""
          customTitle={getCompressedString(selectedItemsLabel || (props?.defaultValue as string[]), 1)}
          placement="bottom"
        >
          <p className={`textEllipsis ${styles.filterEllipsisWidth}`}>
            {getCompressedString(selectedItemsLabel || (props?.defaultValue as string[]), 1)}
          </p>
        </ArTooltip>
        <Arrow {...classNames(styles.multiselectArrow, open && styles.rotate)} />
        <label className={styles.label}>{props.placeholder}</label>
      </div>
    </ArDropdown>
  );
};

export const getDurationLabel = (val: number, options: IDurationOptions[]) => {
  const selectedDuration = options.find((el: IDurationOptions) => el?.value === val);
  return selectedDuration?.label;
};

export const DurationFilter: React.FC<IDurationFilterProps> = ({ options, onChange, value, placeholder, ...props }) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const items = [
    {
      key: "1",
      label: (
        <DurationFilterComponent
          options={options}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          setOpen={setOpen}
          {...props}
        />
      ),
    },
  ];

  return (
    <ArDropdown
      open={open}
      menu={{ items }}
      trigger={["click"]}
      className={`${styles.dropdown} ${value ? styles.selected : null}`}
      overlayClassName={"dropdownListMultiSelect"}
      onOpenChange={() => setOpen((prevVal) => !prevVal)}
    >
      <div className={styles.dropDownValue}>
        <ArTooltip heading="" customTitle={getDurationLabel(value?.id, options)} placement="bottom">
          <p className={`textEllipsis ${styles.filterEllipsisWidth}`}>{getDurationLabel(value?.id, options)}</p>
        </ArTooltip>
        <Arrow {...classNames(styles.multiselectArrow, open && styles.rotate)} />
        <label className={styles.label}>{placeholder}</label>
      </div>
    </ArDropdown>
  );
};

export const DurationFilterOption: React.FC<IDurationFilterProps> = ({
  options,
  onChange,
  value,
  placeholder,
  ...props
}) => {
  return (
    <div className={styles.radioSelectOptions}>
      <p className="hero-text">
        {STRING_CONSTANTS.SELECT} {placeholder}
      </p>
      <DurationFilterComponent
        options={options}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        isMoreFilter={true}
        {...props}
      />
    </div>
  );
};

export const MinMaxFilterOption: React.FC<MinMaxFilterProps> = ({
  defaultValue: { min, max },
  value,
  onChange,
  ...props
}) => {
  const [rangeValue, setRangeValue] = React.useState<[number, number]>(
    value?.min && value?.max ? [value.min, value.max] : [min, max]
  );

  return (
    <div className={styles.radioSelectOptions}>
      <div className={styles.selectCarpetArea}>
        <p className="hero-text">
          {STRING_CONSTANTS.SELECT} {props.placeholder}
        </p>
        <MinMaxFilterComponent
          min={min}
          max={max}
          rangeValue={rangeValue}
          setRangeValue={setRangeValue}
          value={value}
          onChange={onChange}
          isMoreFilter={true}
          {...props}
        />
      </div>
    </div>
  );
};

export const MultiSelectFilterOptions: React.FC<ArMultiSelectProps> = ({ options, onChange, ...props }) => {
  const sortedOptions = useMemo(() => {
    return sortSelectedValues(options, (props.value || []) as string[]);
  }, [options, props.value]);

  return (
    <div className={styles.radioSelectOptions}>
      <p className="hero-text">
        {STRING_CONSTANTS.SELECT} {props.placeholder}
      </p>
      <MultiSelectFilterComponent
        options={sortedOptions}
        onChange={onChange}
        isMoreFilter={true}
        {...props}
      ></MultiSelectFilterComponent>
    </div>
  );
};

export const SingleSelectRadio: React.FC<ArSelectBoxProps> = ({ options, onChange, value, placeholder, ...props }) => {
  const radioOptions = options?.map((option) => {
    return {
      label: option?.label,
      value: option?.value as string,
    };
  });

  const handleChange = (value: string) => {
    const selectedOption = options?.find((option) => option?.value === value);
    onChange && onChange(selectedOption?.value, selectedOption as optionProps);
  };

  return (
    <div className={styles.radioSelectOptions}>
      <p className="hero-text">
        {STRING_CONSTANTS.SELECT} {placeholder}
      </p>
      <ArRadioSelect
        defaultValue={value}
        options={radioOptions || []}
        onChange={handleChange}
        className={`${styles.radioSelect} ${styles.moreFilterRadioSelect}`}
      />
    </div>
  );
};

export const NestedFilter: React.FC<INestedFilterProps> = ({ options, onChange, value, ...props }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedItemsLabel, setSelectedItemsLabel] = React.useState<string[]>([]);

  useEffect(() => {
    const selectedMonths = [];
    for (let i = 0; i < value?.length; i++) {
      const key = value[i];
      const isValid = moment(key, "MM-YYYY", true).isValid();
      if (!isValid) continue;
      if (isValid) {
        const currentKeyMonthTitle = moment(key, "MM-YYYY").format("M");
        const currentKeyYearTitle = moment(key, "MM-YYYY").format("Y");
        selectedMonths.push(`${monthsArr[Number(currentKeyMonthTitle) - 1]}-${currentKeyYearTitle}`);
      }
    }
    if (selectedMonths?.length === 0) {
      setSelectedItemsLabel([STRING_CONSTANTS.ALL_TEXT]);
    } else {
      setSelectedItemsLabel(selectedMonths);
    }
  }, [value]);
  const items = [
    {
      key: "1",
      label: (
        <NestedFilterComponent
          options={options}
          onChange={onChange}
          value={value}
          setOpen={setOpen}
          {...props}
        ></NestedFilterComponent>
      ),
    },
  ];

  return (
    <ArDropdown
      open={open}
      menu={{ items }}
      trigger={["click"]}
      className={`${styles.dropdown}  ${value?.length ? styles.selected : null}`}
      overlayClassName={"dropdownListMultiSelect"}
      onOpenChange={() => setOpen((prevVal) => !prevVal)}
    >
      <div className={styles.dropDownValue}>
        <ArTooltip heading="" customTitle={getCompressedString(selectedItemsLabel, 1)} placement="bottom">
          <p className={`textEllipsis ${styles.filterEllipsisWidth}`}>{getCompressedString(selectedItemsLabel, 1)}</p>
        </ArTooltip>
        <Arrow {...classNames(styles.multiselectArrow, open && styles.rotate)} />
        <label className={styles.label}>{props.placeholder}</label>
      </div>
    </ArDropdown>
  );
};

export const NestedFilterOptions: React.FC<INestedFilterProps> = ({ options, onChange, value, ...props }) => {
  return (
    <div className={styles.radioSelectOptions}>
      <p className="hero-text">
        {STRING_CONSTANTS.SELECT} {props.placeholder}
      </p>
      <NestedFilterComponent options={options} onChange={onChange} value={value} {...props}></NestedFilterComponent>
    </div>
  );
};

export const Filter: React.FC<FilterPropsType> = ({ filterType, filterProps }) => {
  switch (filterType) {
    case FILTER_TYPES.SINGLE_SELECT:
      return <SingleSelect {...(filterProps as ArSelectBoxProps)} />;
    case FILTER_TYPES.MULTI_SELECT:
      return <MultiSelectFilter {...(filterProps as ArMultiSelectProps)} />;
    case FILTER_TYPES.NUMERIC_RANGE:
      return <MinMaxFilter {...(filterProps as MinMaxFilterProps)} />;
    case FILTER_TYPES.DATE_RANGE:
      return <DurationFilter {...(filterProps as IDurationFilterProps)} />;
    case FILTER_TYPES.NESTED:
      return <NestedFilter {...(filterProps as INestedFilterProps)} />;
    case FILTER_TYPES.TAB:
      return (
        <span className="d-flex">
          <SingleSelect {...(filterProps as ArSelectBoxProps)} /> <span className={styles.filterSeparator}></span>
        </span>
      );
    default:
      return null;
  }
};

export const MoreFilter: React.FC<FilterPropsType> = ({ filterType, filterProps }) => {
  switch (filterType) {
    case FILTER_TYPES.SINGLE_SELECT:
      return <SingleSelectRadio {...(filterProps as ArSelectBoxProps)} />;
    case FILTER_TYPES.MULTI_SELECT:
      return <MultiSelectFilterOptions {...(filterProps as ArMultiSelectProps)} />;
    case FILTER_TYPES.NUMERIC_RANGE:
      return <MinMaxFilterOption {...(filterProps as MinMaxFilterProps)} />;
    case FILTER_TYPES.DATE_RANGE:
      return <DurationFilterOption {...(filterProps as IDurationFilterProps)} />;
    default:
      return null;
  }
};

export const FilterShimmer: React.FC = () => {
  const Filter = <Skeleton.Input active={true} className={styles.filterShimmerItem} />;
  return (
    <div className={styles.filterShimmerBar}>
      {Filter}
      {Filter}
      {Filter}
      {Filter}
    </div>
  );
};
