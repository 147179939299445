import { ArButton, ArForm, ArModal, ArModalProps, ButtonTypes, FormNode, nodeType } from "@anarock/ar-common-react";
import { useForm } from "antd/es/form/Form";
import { IClientPOC, IPOCModule, useAddClientPOCMutation, useEditClientPOCMutation } from "app/services/invoices";
import { isNotNullorUndefined } from "app/utils/helperFunctions";
import styles from "components/LandLordListModal/index.module.scss";
import { emailRules, requiredRulesOnChange } from "components/requirmentFormUi/rules";
import { useToastService } from "components/toastService";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import { PhoneNumberInputNode } from "features/wip/onboarding/partials";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { ModalFormEditFlowInfoBar, RequiredPhoneNumberNode, RequiredTextNode } from "../formHelpers";

interface AddClientPOCModalProps extends Omit<ArModalProps, "onCancel"> {
  onCancel?: () => void;
  clientId: string | undefined;
  clientType: IPOCModule;
  editBrandPOCData?: IClientPOC | undefined;
  sendDataAndCloseModal?: (poc: IClientPOC) => void;
}

export const AddClientPOCModal: React.FC<AddClientPOCModalProps> = ({
  onSubmit,
  editBrandPOCData,
  sendDataAndCloseModal,
  ...props
}) => {
  const [form] = useForm();
  const { errorToast, successToast } = useToastService();

  const [addClientPOC] = useAddClientPOCMutation();
  const [editBrandPOC] = useEditClientPOCMutation();
  const [alternateNumbers, setAlternateNumbers] = useState<string[]>([uuidv4()]);

  const editFlow = isNotNullorUndefined(editBrandPOCData?.name);

  useEffect(() => {
    if (editBrandPOCData && props?.open) {
      form.setFieldsValue({
        [FORM_FIELDS.POC_NAME]: editBrandPOCData?.name,
        [FORM_FIELDS.POC_EMAIL]: editBrandPOCData?.email,
        [FORM_FIELDS.POC_NUMBER]: editBrandPOCData?.phoneNumber?.number,
      });
      if (editBrandPOCData?.alternatePhoneNumbers?.length) {
        setAlternateNumbers(Array.from({ length: editBrandPOCData?.alternatePhoneNumbers?.length }, () => uuidv4()));
      }
    } else if (props?.open) {
      form.resetFields();
    }
  }, [editBrandPOCData, form, props?.open]);

  const getClientPOCBody = (validatedForm: any) => {
    return {
      module: props?.clientType,
      moduleEntryId: props.clientId,
      clientId: props.clientId,
      name: validatedForm[FORM_FIELDS.POC_NAME],
      email: validatedForm[FORM_FIELDS.POC_EMAIL],
      // designation: validatedForm[FORM_FIELDS.POC_DESIGNATION],
      phoneNumber: {
        countryCode: "+91",
        number: validatedForm[FORM_FIELDS.POC_NUMBER],
      },
      alternatePhoneNumbers: alternateNumbers
        .map((item) => ({
          countryCode: "+91",
          number: validatedForm[STRING_CONSTANTS.ALTERNATE_PHONE_NUMBER + item],
        }))
        ?.filter((item) => item?.number),
    };
  };

  const handleSubmitModal = async () => {
    try {
      const validatedForm = await form.validateFields();
      let clientPOCResponse;
      try {
        clientPOCResponse = await addClientPOC(getClientPOCBody(validatedForm)).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
      if (clientPOCResponse?.success) {
        successToast(STRING_CONSTANTS.POC_ADDED_SUCCESSFULLY);
        props?.onCancel?.();
      } else {
        errorToast(clientPOCResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    } catch {
      errorToast(TOAST_MESSAGES.ENTER_VALID_DATA);
    }
  };

  const handleUpdateModal = async () => {
    try {
      const validatedForm = await form.validateFields();
      let BrandPOCResponse;
      try {
        BrandPOCResponse = await editBrandPOC({
          _id: editBrandPOCData?._id,
          ...getClientPOCBody(validatedForm),
        }).unwrap();
      } catch {
        errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }

      if (BrandPOCResponse?.success) {
        sendDataAndCloseModal && sendDataAndCloseModal(BrandPOCResponse?.data?.poc);
        successToast(STRING_CONSTANTS.POC_EDITED_SUCCESSFULLY);
      } else {
        errorToast(BrandPOCResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
      }
    } catch {
      errorToast(TOAST_MESSAGES.ENTER_VALID_DATA);
    }
  };

  const addAlternateNumber = () => {
    const arr = [...alternateNumbers];
    arr.push(uuidv4());
    setAlternateNumbers(arr);
  };

  const removeAlternateNumber = (idx: number) => {
    const arr = [...alternateNumbers];
    arr.splice(idx, 1);
    setAlternateNumbers(arr);
  };

  const AddClientPOCForm: FormNode = {
    type: nodeType.array,
    childNode: [
      RequiredTextNode(FORM_FIELDS.POC_NAME, FORM_FIELDS.CLIENT_POC_NAME, "", undefined, requiredRulesOnChange),
      RequiredTextNode(FORM_FIELDS.POC_EMAIL, FORM_FIELDS.CLIENT_POC_EMAIL, undefined, undefined, emailRules),
      RequiredPhoneNumberNode(FORM_FIELDS.POC_NUMBER, FORM_FIELDS.CLIENT_POC_NO, undefined, undefined),
      ...alternateNumbers.map((item, idx) =>
        PhoneNumberInputNode(
          STRING_CONSTANTS.ALTERNATE_PHONE_NUMBER + item,
          STRING_CONSTANTS.ALTERNATE_PHONE_NUMBER,
          (editBrandPOCData?.alternatePhoneNumbers?.[idx]?.number as string) || "",
          undefined,
          idx === 0 ? (
            <ArButton
              type={ButtonTypes.Link}
              onClick={() => addAlternateNumber()}
              disabled={alternateNumbers.length > 4}
            >
              {STRING_CONSTANTS.ADD_ALTERNATE}
            </ArButton>
          ) : (
            <ArButton type={ButtonTypes.Link} onClick={() => removeAlternateNumber(idx)}>
              {STRING_CONSTANTS.REMOVE}
            </ArButton>
          )
        )
      ),
    ],
  };

  return (
    <ArModal
      onSubmit={editFlow ? handleUpdateModal : handleSubmitModal}
      width={"500px"}
      {...props}
      submitText={editFlow ? STRING_CONSTANTS.EDIT_DETAILS : STRING_CONSTANTS.ADD_NEW_CLIENT_POC}
      className={styles.selectBoxWidth}
    >
      <div className={"listModal"}>
        {editFlow ? <ModalFormEditFlowInfoBar /> : null}
        <ArForm form={form} formNode={AddClientPOCForm} className={styles.formContainer} />
      </div>
    </ArModal>
  );
};
