import { PAYMENTS_URLS } from "constants/ApiUrls";
import { PAYMENT_CLAIM_STATUS } from "constants/index";
import { IUser } from "features/authentication/authSlice";

import { IAnalyticsFilters, IFilterListResponse } from "./analytics";
import { api, GenericResponse, paginationParams } from "./api";
import { IComment, IInvoice } from "./collections";
import { ISortBody, ISuggestedIRF, IUploadUrl } from "./invoices";
import { ISelectedFilters } from "./listingPageApi";
import { IImage, IPaginationParam } from "./notificationsApi";

export interface IFiltersRequest {
  selectedFilters: ISelectedFilters;
  defaultVal?: boolean;
}

export interface IInvoiceWihtOutstanding {
  _id: string;
  projectId: string;
  client: {
    name: string;
    type: string;
  };
  invoiceNo: string;
  outstanding: number;
  invoiceValue: number;
  landLordType: "Individual" | "Brand";
}

export type InvoiceListWithOutstandingResponse = GenericResponse<{ invoices: IInvoiceWihtOutstanding[] }>;
export interface IPaymentClaimApproval {
  approvalStatus: PAYMENT_CLAIM_STATUS;
  comment?: string;
  reason?: string[];
  lastUpdatedAt?: string;
}

export interface IInvoiceForPaymentClaim extends IInvoice {
  collectedWithoutGst?: number;
  tdsCollected?: number;
  dealType?: string;
  currentPayment?: {
    paymentAmount?: number;
    tdsAmount?: number;
  };
}

export interface IInvoices {
  invoiceNo: string;
  paymentAmount: number;
  tdsAmount: number;
}
export interface IPaymentClaim {
  _id?: string;
  paymentDate?: string;
  description?: string;
  paymentId: string;
  paymentProofURL?: IImage;
  totalPaymentAmount?: number;
  comment?: {
    text: string;
  };
  invoiceList?: IInvoiceForPaymentClaim[];
  approval?: IPaymentClaimApproval;
  invoices?: IInvoices[];
  agents?: IUser[];
  cityLead?: IUser;
  invoiceDocs?: IImage[];
  profitCenter?: string;
  paymentClaimType?: string;
  paymentObject?: {
    type: string;
    chequeNumber?: string;
    chequeDate?: string;
    utrNumber?: string;
    paymentDate?: string;
  };
  chequeDepositProofURL?: IImage;
  monthKey?: string;
  brandManager?: {
    name: string;
    employeeId: string;
  };
}

export interface IPaymentsRequestBody {
  selectedFilters: IAnalyticsFilters;
  query?: string;
  params: paginationParams;
  sort?: ISortBody;
  uid: string;
  projectId?: string;
}

export type IPaymentListResponse = GenericResponse<{
  paymentClaims: IPaymentClaim[];
  paginationParams: IPaginationParam;
}>;

interface IPaymentSuggestion extends ISuggestedIRF {
  paymentId: string;
}

export type SuggestedClaimPaymentResponse = GenericResponse<{ paymentClaims: IPaymentSuggestion[] }>;
export type IEmptyResponse = GenericResponse<void>;

export type ISignedUrlBasicResponse = GenericResponse<{ uploadURL: IUploadUrl }>;

export const invoicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    paymentsFilters: builder.query<IFilterListResponse, IFiltersRequest>({
      query: ({ selectedFilters, defaultVal }) => ({
        url: `${PAYMENTS_URLS.PAYMENT}${PAYMENTS_URLS.FILTERS}`,
        method: "POST",
        body: { selectedFilters, defaultVal },
      }),
    }),
    invoiceListWithOutstanding: builder.query<InvoiceListWithOutstandingResponse, void>({
      query: () => ({
        url: `${PAYMENTS_URLS.INVOICE_LIST}`,
        method: "GET",
      }),
      providesTags: ["PaymentList"],
    }),
    getPaymentList: builder.query<IPaymentListResponse, IPaymentsRequestBody>({
      query: ({ selectedFilters, params, sort, query, uid, projectId }) => ({
        url: `${PAYMENTS_URLS.PAYMENT}${PAYMENTS_URLS.LIST}`,
        method: "POST",
        body: { selectedFilters, sort, query, uid, projectId },
        params,
      }),
      providesTags: ["PaymentList"],
    }),
    paymentCommentList: builder.query<GenericResponse<{ comments: IComment[] }>, string | null | undefined>({
      query: (paymentId) => ({
        url: `${PAYMENTS_URLS.COMMENT}${PAYMENTS_URLS.PAYMENTS_CLAIM}/${paymentId}`,
        method: "GET",
      }),
      providesTags: ["PaymentComment"],
    }),
    postCommentToPayment: builder.mutation<
      GenericResponse<void>,
      { paymentId: string | null | undefined; text: string }
    >({
      query: ({ paymentId, text }) => ({
        url: `${PAYMENTS_URLS.COMMENT}${PAYMENTS_URLS.PAYMENTS_CLAIM}/${paymentId}`,
        method: "POST",
        body: { text: text },
      }),
      invalidatesTags: ["PaymentComment", "PaymentList"],
    }),
    getPaymentsSuggestions: builder.mutation<SuggestedClaimPaymentResponse, string>({
      query: (query) => ({
        url: PAYMENTS_URLS.AUTO_SUGGEST,
        method: "POST",
        body: {
          query,
          limit: 7,
        },
      }),
    }),
    getPaymentById: builder.query<GenericResponse<{ paymentClaim: IPaymentClaim }>, string>({
      query: (paymentId) => ({
        url: `${PAYMENTS_URLS.GET_PAYMENT_BY_ID}/${paymentId}`,
        method: "GET",
      }),
      providesTags: ["PaymentList"],
    }),
    getSignedUrlForPaymentProof: builder.mutation<ISignedUrlBasicResponse, string>({
      query: (extension) => ({
        url: PAYMENTS_URLS.GET_SIGNED_URL,
        method: "GET",
        params: { extension },
      }),
    }),
    createPayment: builder.mutation<GenericResponse<void>, IPaymentClaim>({
      query: (body) => ({
        url: PAYMENTS_URLS.PAYMENT,
        method: "POST",
        body,
      }),
      invalidatesTags: ["PaymentList"],
    }),
    editPayment: builder.mutation<IEmptyResponse, IPaymentClaim>({
      query: (body) => ({
        url: `${PAYMENTS_URLS.PAYMENT}/${body?.paymentId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["PaymentList"],
    }),
  }),
});

export const {
  usePaymentsFiltersQuery,
  useGetPaymentListQuery,
  usePaymentCommentListQuery,
  usePostCommentToPaymentMutation,
  useInvoiceListWithOutstandingQuery,
  useGetPaymentsSuggestionsMutation,
  useGetPaymentByIdQuery,
  useGetSignedUrlForPaymentProofMutation,
  useCreatePaymentMutation,
  useEditPaymentMutation,
} = invoicesApi;
