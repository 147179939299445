import { ArDatePicker, ArRadioSelect } from "@anarock/ar-common-react";
import { disabledFutureDate } from "app/utils/helperFunctions";
import { useToastService } from "components/toastService";
import { DATE_KEYS } from "constants/ListingEnums";
import { STRING_CONSTANTS } from "constants/StringConstants";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";

import { IDurationFilterProps, IDurationOptions } from "../../index.interface";
import styles from "../../index.module.scss";
import ResetApplyButtonComponent from "../ResetApplyButtonComponent";

const DurationFilterComponent: React.FC<IDurationFilterProps> = ({
  options,
  onChange,
  value,
  placeholder,
  isMoreFilter = false,
  setOpen,
  ...props
}) => {
  const [durationObj, setDurationObj] = useState(value);
  const { errorToast } = useToastService();

  useEffect(() => {
    setDurationObj(value);
  }, [value]);

  const handleDateChange = (date: Dayjs | null, dateString: string, key: string) => {
    if (key === DATE_KEYS.FROM_DATE && durationObj?.toDate) {
      const isAfter = dayjs(dateString).isAfter(dayjs(durationObj?.toDate));
      if (isAfter) {
        errorToast(STRING_CONSTANTS.START_DATE_AFTER_END_DATE_ERROR);
        return;
      }
    } else if (key === DATE_KEYS.TO_DATE && durationObj?.fromDate) {
      const isBefore = dayjs(dateString).isBefore(dayjs(durationObj?.fromDate));
      if (isBefore) {
        errorToast(STRING_CONSTANTS.END_DATE_BEFORE_START_DATE_ERROR);
        return;
      }
    }
    setDurationObj({
      ...durationObj,
      [key]: dayjs(date).format(DATE_KEYS.DATE_FORMAT_API),
    });
    if (isMoreFilter) {
      onChange({
        ...value,
        [key]: dayjs(date).format(DATE_KEYS.DATE_FORMAT_API),
      });
    }
  };

  const getFormattedOptions = (options: IDurationOptions[]) => {
    return options?.map((el: IDurationOptions) => {
      if (el?.value === 0) {
        return {
          label: el.label,
          value: el.value.toString(),
          footerNode: (
            <>
              {durationObj?.id === 0 ? (
                <div className={styles.datePickerWrapper}>
                  <ArDatePicker
                    placeholder={STRING_CONSTANTS.START_DATE}
                    value={durationObj?.fromDate ? dayjs(durationObj?.fromDate) : null}
                    format={DATE_KEYS.DATE_FORMAT_UI}
                    onChange={(date: Dayjs | null, dateString: string) =>
                      handleDateChange(date, dateString, DATE_KEYS.FROM_DATE)
                    }
                    disabledDate={disabledFutureDate}
                  />
                  <div className={styles.datePickerSeparator}>{STRING_CONSTANTS.TO}</div>
                  <ArDatePicker
                    placeholder={STRING_CONSTANTS.END_DATE}
                    value={durationObj?.toDate ? dayjs(durationObj?.toDate) : null}
                    format={DATE_KEYS.DATE_FORMAT_UI}
                    onChange={(date: Dayjs | null, dateString: string) =>
                      handleDateChange(date, dateString, DATE_KEYS.TO_DATE)
                    }
                    disabledDate={disabledFutureDate}
                  />
                </div>
              ) : null}
            </>
          ),
        };
      } else {
        return { label: el?.label, value: el?.value?.toString() };
      }
    });
  };

  const handleChange = (val: number) => {
    setDurationObj({
      id: val,
      [DATE_KEYS.FROM_DATE]: dayjs().subtract(15, "day").format(DATE_KEYS.DATE_FORMAT_API),
      [DATE_KEYS.TO_DATE]: dayjs().format(DATE_KEYS.DATE_FORMAT_API),
    });
    if (isMoreFilter) {
      onChange({
        id: val,
        [DATE_KEYS.FROM_DATE]: dayjs().subtract(15, "day").format(DATE_KEYS.DATE_FORMAT_API),
        [DATE_KEYS.TO_DATE]: dayjs().format(DATE_KEYS.DATE_FORMAT_API),
      });
    }
  };

  const handleApply = () => {
    setOpen && setOpen(false);
    onChange && onChange(durationObj);
  };

  const handleReset = () => {
    setDurationObj(props?.defaultValue);
    onChange && onChange(props?.defaultValue);
  };

  return (
    <>
      <ArRadioSelect
        defaultValue={durationObj?.id?.toString()}
        value={durationObj?.id?.toString()}
        options={getFormattedOptions(options)}
        onChange={(value) => handleChange(Number(value))}
        className={`${isMoreFilter ? `${styles.radioSelect} ${styles.moreFilterRadioSelect}` : styles.radioSelect}`}
      />
      {!isMoreFilter ? <ResetApplyButtonComponent handleApply={handleApply} handleReset={handleReset} /> : null}
    </>
  );
};

export default DurationFilterComponent;
