import { ArModal } from "@anarock/ar-common-react";
import React from "react";

import { GenericConfirmationModalProps } from "./index.interface";

const GenericConfirmationModal = ({ body, ...props }: GenericConfirmationModalProps) => {
  return (
    <ArModal width={360} {...props}>
      <p>{body}</p>
    </ArModal>
  );
};
export default GenericConfirmationModal;
