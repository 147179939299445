// import { ArButton, ButtonTypes } from "@anarock/ar-common-react";
// import { DownloadOutlined } from "@ant-design/icons";
import { ISelectedFilters } from "app/services/listingPageApi";
import { useAllocationReportFiltersQuery } from "app/services/reports";
import { useFiltersWithQuery } from "app/utils/useFiltersHook";
// import { useGenerateBrochureHook } from "app/utils/useGenerateBrochureHook";
// import GenericConfirmationModal from "components/appModals/genericConfirmationModal";
import { ListingFilterBar } from "components/filterBar";
// import { STRING_CONSTANTS } from "constants/StringConstants";
import React, { useMemo } from "react";

import styles from "../index.module.scss";
import RevenueAllocationReportTable from "./components/AllocationReportTable";

const RevenueAllocationReport = () => {
  const { selectedFilters } = useFiltersWithQuery<ISelectedFilters>();

  // const [openPreDownloadModal, setOpenPreDownloadModal] = useState(false);

  // const { generateRevenueAllocationReportExcelSheet } = useGenerateBrochureHook();

  const { data: filtersData, isLoading } = useAllocationReportFiltersQuery(
    {
      selectedFilters: selectedFilters,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const filters = useMemo(() => filtersData?.data?.filters, [filtersData?.data?.filters]);
  const moreFilters = useMemo(() => filtersData?.data?.moreFilters, [filtersData?.data?.moreFilters]);

  // const handleDownloadReport = () => {
  //   setOpenPreDownloadModal(true);
  // };

  return (
    <div>
      <ListingFilterBar
        filters={filters || []}
        moreFilters={moreFilters || []}
        loading={isLoading}
        // button={
        //   <div className="pr-24">
        //     <ArButton type={ButtonTypes.Outlined} onClick={handleDownloadReport}>
        //       <DownloadOutlined className="pr-8" />
        //       {STRING_CONSTANTS.DOWNLOAD_ALLOCATION_REPORT}
        //     </ArButton>
        //   </div>
        // }
      />
      <div className={styles.tableContainer}>
        <RevenueAllocationReportTable />
      </div>
      {/* <GenericConfirmationModal
        body={<div className="pb-20">{STRING_CONSTANTS.DOWNLOAD_REPORT_BODY}</div>}
        open={openPreDownloadModal}
        onCancel={() => setOpenPreDownloadModal(false)}
        closeModal={() => setOpenPreDownloadModal(false)}
        title={STRING_CONSTANTS.DOWNLOAD_CONFIRMATION}
        submitText={STRING_CONSTANTS.DOWNLOAD_REPORT}
        onSubmit={() => {
          setOpenPreDownloadModal(false);
          generateRevenueAllocationReportExcelSheet();
        }}
        cancelText={STRING_CONSTANTS.GO_BACK}
      ></GenericConfirmationModal> */}
    </div>
  );
};

export default RevenueAllocationReport;
