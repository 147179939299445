import {
  ArBaseContainer,
  ArButton,
  ArPropertyFeatures,
  ArPropertyImageContainer,
  ArPropertyUpdateStatus,
  ButtonTypes,
} from "@anarock/ar-common-react";
import colors from "@anarock/ar-common-react/dist/styles/partials/_colors.module.scss";
import ArTooltip from "@anarock/ar-common-react/dist/ui-components/ar-tooltip";
import { classNames } from "@anarock/ar-common-react/dist/utils/classNames";
import { Spin } from "antd";
import { useGetConsultingWipDetailsQuery } from "app/services/consultingWip";
import { useGetStaticEnumsQuery } from "app/services/enums";
import { useGetShopDetailsQuery } from "app/services/shopDetailsApi";
import { getCompressedString, getShopImages, getValueOrDash, isNullOrUndefiend } from "app/utils/helperFunctions";
import { getPropertyDetails } from "app/utils/shopPropsTransformer";
import { ShopFeature } from "components/shopDetailComponents/basicImageInfoCard";
import { WIPUpdateBillingMilestoneModal } from "components/wipUpdateBillingMilestoneModal";
import { WIP_APPROVAL_STATUS } from "constants/index";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { getBillingMilestoneTooltipText, getBillingMilstoneText } from "features/wip/partials";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import styles from "../../../wip/components/WIP-Header/index.module.scss";
import UpdateConsultingWipStatusModal from "../UpdateConsultingWipStatusModal";

const ConsultingWIPHeader = () => {
  const [billingModal, setBillingModal] = useState(false);
  const [updateWipModal, setUpdateWipModal] = useState(false);

  const { projectId } = useParams();

  const { data: enums } = useGetStaticEnumsQuery();
  const { data: wip, isFetching: fetchingWIP } = useGetConsultingWipDetailsQuery(projectId || "", {
    skip: isNullOrUndefiend(projectId),
  });

  const { data: shop, isFetching } = useGetShopDetailsQuery(wip?.data?.wip?.property?.shopId || "", {
    skip: isNullOrUndefiend(wip?.data?.wip?.property?.shopId),
  });

  const IS_DEAL_BILLED = wip?.data?.wip?.status?.wipStatusId === enums?.data?.wipStatus?.BILLED;
  const IS_DEAL_DROPPED = wip?.data?.wip?.approval?.approvalStatus === WIP_APPROVAL_STATUS.DROPPED;

  const isEditable =
    !IS_DEAL_BILLED &&
    !IS_DEAL_DROPPED &&
    Boolean(wip?.data?.wip?.isEditable) &&
    (wip?.data?.wip?.invoicesCount || 0) < 1;

  //color based on wip state
  const color = IS_DEAL_DROPPED ? colors["coral-400"] : colors["primary-400"];

  // button type to be decided on theme level
  const type = IS_DEAL_DROPPED ? ButtonTypes.OutlinedCoral : ButtonTypes.Outlined;
  const type2 = IS_DEAL_DROPPED ? ButtonTypes.LinkCoral : ButtonTypes.Link;

  const toggleBillingModal = () => {
    setBillingModal(!billingModal);
  };
  const toggleUpdateWipModal = () => {
    setUpdateWipModal(!updateWipModal);
  };

  if (isFetching || fetchingWIP) {
    return <Spin tip={STRING_CONSTANTS.LOADING_PLEASE_WAIT} size="large" />;
  }

  const shopImages = shop?.data?.shop?.images ? getShopImages(shop?.data?.shop?.images) : [];
  const { propertyFeatures, propertyUpdateStatus } = shop?.data?.shop
    ? getPropertyDetails(shop?.data?.shop)
    : { propertyFeatures: null, propertyUpdateStatus: null };

  return (
    <div {...classNames(styles.headerWrapper)}>
      <ArBaseContainer style={{ backgroundColor: color }} {...classNames(styles.updateHeader, styles.showUpdate)}>
        <div className={styles.updateHeaderBox}>
          <ArTooltip heading={getValueOrDash(wip?.data?.wip?.status?.wipStatus?.title) as string}>
            <p className={`${styles.whiteText} ${styles.wipStatus}`}>
              {getValueOrDash(wip?.data?.wip?.status?.wipStatus?.title) as string}
            </p>
          </ArTooltip>
          {!IS_DEAL_BILLED && !IS_DEAL_DROPPED && Boolean(wip?.data?.wip?.isEditable) && (
            <ArButton type={type} size={"middle"} onClick={toggleUpdateWipModal} className={styles.updateBtn}>
              {STRING_CONSTANTS.UPDATE_WIP_STATUS}
            </ArButton>
          )}
        </div>
        {wip?.data?.wip?.billingMilestones?.length ? (
          <div className={styles.updateHeaderBox}>
            <ArTooltip
              heading=""
              customTitle={getBillingMilestoneTooltipText(wip?.data?.wip?.billingMilestones)}
              placement="bottom"
            >
              <p className={`${styles.whiteText} ${styles.wipBillingMilestone}`}>
                {getBillingMilstoneText(wip?.data?.wip?.billingMilestones)}
              </p>
            </ArTooltip>
            {isEditable && (
              <ArButton type={type2} size={"middle"} className={styles.whiteText} onClick={toggleBillingModal}>
                {STRING_CONSTANTS.CHANGE}
              </ArButton>
            )}
          </div>
        ) : (
          <div className={styles.updateHeaderBox}>
            <ArButton type={type2} size={"middle"} className={styles.whiteText} onClick={toggleBillingModal}>
              {STRING_CONSTANTS.SELECT_BILLING_MILETSONES}
            </ArButton>
          </div>
        )}
      </ArBaseContainer>
      <ArBaseContainer {...classNames(styles.shopDetails, styles.showUpdate)}>
        {shop?.data?.shop ? (
          <div className={styles.shopContainer}>
            <ArPropertyImageContainer shopImages={shopImages} />
            <div className={styles.shopDetails}>
              <div className={styles.propertyName}>
                <p className={styles.name}>{shop?.data?.shop?.shopNumber}</p>
                <p className={styles.address}>{shop?.data?.shop?.address}</p>
              </div>
              {propertyFeatures ? <ArPropertyFeatures {...propertyFeatures} /> : null}
              {propertyUpdateStatus ? (
                <ArPropertyUpdateStatus {...propertyUpdateStatus} askToUpdateButton={false} />
              ) : null}
            </div>
          </div>
        ) : null}

        <div className={styles.detailContainer}>
          <ShopFeature
            label={STRING_CONSTANTS.BILLING_CLIENT}
            value={getValueOrDash(wip?.data?.wip?.wipMetaData?.billingClient) as string}
          />

          <ShopFeature label={FORM_FIELDS.WIP_TYPE_LABEL} value={STRING_CONSTANTS.CONSULTING} />

          <ShopFeature
            label={STRING_CONSTANTS.LOCATION}
            value={getCompressedString(wip?.data?.wip?.cities?.map(({ name }) => name)) || "-"}
          />
          <ShopFeature
            label={STRING_CONSTANTS.PROJECT_ID}
            value={getValueOrDash(wip?.data?.wip?.project?.id) as string}
          />
        </div>
      </ArBaseContainer>
      {billingModal ? (
        <WIPUpdateBillingMilestoneModal
          open={billingModal}
          projectId={projectId || ""}
          toggleModal={toggleBillingModal}
          value={wip?.data?.wip?.billingMilestones}
          wipType="CONSULTING"
        />
      ) : null}
      {updateWipModal ? (
        <UpdateConsultingWipStatusModal
          openModal={updateWipModal}
          toggleModal={toggleUpdateWipModal}
          currentStatus={wip?.data?.wip?.status?.wipStatusId}
          currentLocationId={wip?.data?.wip?.wipMetaData?.requirement?.brandRequirementDetailId}
          projectId={projectId || ""}
        />
      ) : null}
    </div>
  );
};

export default ConsultingWIPHeader;
