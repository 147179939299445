import { ArMultiSelect } from "@anarock/ar-common-react";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { MultiSelectFilterComponentProps } from "components/filterBar/index.interface";
import React, { useEffect, useState } from "react";

import ResetApplyButtonComponent from "../ResetApplyButtonComponent";

const MultiSelectFilterComponent: React.FC<MultiSelectFilterComponentProps> = ({
  options,
  onChange,
  isMoreFilter = false,
  setOpen,
  defaultValue,
  showSearch = true,
  ...props
}) => {
  const [value, setValue] = useState(props.value as CheckboxValueType[] | undefined);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (value: CheckboxValueType[]) => {
    if (JSON.stringify(value) === JSON.stringify(props.value)) return;
    setValue(value as string[]);
    if (isMoreFilter) {
      onChange && onChange(value as string[]);
    }
  };

  const handleApply = () => {
    setOpen && setOpen(false);
    onChange && onChange(value as string[]);
  };

  const handleReset = () => {
    setValue(defaultValue);
    onChange && onChange(defaultValue as CheckboxValueType[]);
  };

  return (
    <>
      <ArMultiSelect
        options={options}
        onChange={(value) => handleChange(value)}
        defaultValue={value}
        showSearch={showSearch}
        {...props}
      ></ArMultiSelect>
      {!isMoreFilter ? <ResetApplyButtonComponent handleApply={handleApply} handleReset={handleReset} /> : null}
    </>
  );
};

export default MultiSelectFilterComponent;
