import {
  ArButton,
  ArInput,
  ArInputType,
  ArModal,
  ArModalProps,
  ButtonTypes,
  varientType,
} from "@anarock/ar-common-react";
import { useDeleteConsultingWipMutation, useUpdateConsultingWipDetailsMutation } from "app/services/consultingWip";
import { useDeleteDraftWIPMutation, useUpdateWipDetailsMutation } from "app/services/wip";
import { useToastService } from "components/toastService";
import { WIP_APPROVAL_STATUS, WIP_APPROVAL_SUB_STATUS } from "constants/index";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import React, { useState } from "react";

import { WIPDeclineModalProps } from "./index.interface";

export const WIPChangeBrokerageModal = ({ open, onCancel, onSubmit, confirmLoading }: ArModalProps) => {
  return (
    <ArModal
      open={open}
      onCancel={onCancel}
      title={STRING_CONSTANTS.CHANGE_BROKERAGE_DETAILS_TITLE}
      submitText={STRING_CONSTANTS.YES_GOTO_STEP_3}
      cancelText={STRING_CONSTANTS.DISCARD_CHANGES}
      onSubmit={onSubmit}
      confirmLoading={confirmLoading}
    >
      <p>{STRING_CONSTANTS.CHANGE_BROKERAGE_TEXT}</p>
    </ArModal>
  );
};

export const WIPDeclineModal = ({ open, onCancel, approval, closeModal, wipType, projectId }: WIPDeclineModalProps) => {
  const [updateWIP, { isLoading }] = useUpdateWipDetailsMutation();
  const [updateConsultingWIP, { isLoading: isConsultingLoading }] = useUpdateConsultingWipDetailsMutation();
  const { errorToast } = useToastService();
  const [comment, setComment] = useState("");

  const isNewWip = approval?.approvalSubStatus === WIP_APPROVAL_SUB_STATUS.PENDING;

  const heading = isNewWip ? STRING_CONSTANTS.REASON_TO_DECLINE_NEW_WIP : STRING_CONSTANTS.REASON_TO_DECLINE_WIP;

  const text = isNewWip ? STRING_CONSTANTS.NEW_WIP_DECLINE_TEXT : STRING_CONSTANTS.WIP_DECLINE_TEXT;

  const onDeclineClick = async () => {
    let res;
    try {
      if (wipType === "CONSULTING") {
        res = await updateConsultingWIP({
          projectId,
          approval: {
            ...approval,
            approvalStatus: WIP_APPROVAL_STATUS.DECLINED,
            comment,
          },
        }).unwrap();
      } else {
        res = await updateWIP({
          projectId,
          approval: {
            ...approval,
            approvalStatus: WIP_APPROVAL_STATUS.DECLINED,
            comment,
          },
        }).unwrap();
      }
    } catch (e) {
      errorToast(TOAST_MESSAGES.WIP_SAVED_ERROR);
    }
    if (!res?.success) {
      errorToast(TOAST_MESSAGES.WIP_SAVED_ERROR);
    } else {
      closeModal();
      errorToast(TOAST_MESSAGES.WIP_DECLINED_SUCCESSFULLY);
    }
  };
  return (
    <ArModal
      open={open}
      onCancel={onCancel}
      title={heading}
      footer={
        <ArButton
          type={ButtonTypes.Reject}
          onClick={onDeclineClick}
          loading={isLoading || isConsultingLoading}
          disabled={comment === ""}
        >
          {STRING_CONSTANTS.DECLINE}
        </ArButton>
      }
    >
      <div className="pb-16">
        <p className="pb-20">{text}</p>
        <ArInput
          type={ArInputType.text}
          label={STRING_CONSTANTS.PLEASE_COMMENT_TO_DECLINE}
          required
          value={comment}
          onChange={(value) => setComment(value as string)}
          varient={varientType.default}
        ></ArInput>
      </div>
    </ArModal>
  );
};

export const WIPApproveModal = ({ open, onCancel, approval, closeModal, wipType, projectId }: WIPDeclineModalProps) => {
  const [updateWIP, { isLoading }] = useUpdateWipDetailsMutation();
  const [updateConsultingWIP, { isLoading: isConsultingLoading }] = useUpdateConsultingWipDetailsMutation();
  const { errorToast, successToast } = useToastService();

  const isNewWip = approval?.approvalSubStatus === WIP_APPROVAL_SUB_STATUS.PENDING;

  const heading = STRING_CONSTANTS.APPROVE_CONFIRMATION;

  const text = isNewWip ? STRING_CONSTANTS.APPROVE_NEW_WIP_TEXT : STRING_CONSTANTS.APPROVE_WIP_TEXT;

  const onApproveClick = async () => {
    let res;
    try {
      if (wipType === "CONSULTING") {
        res = await updateConsultingWIP({
          projectId,
          approval: {
            ...approval,
            approvalStatus: WIP_APPROVAL_STATUS.APPROVED,
            isSubmitted: true,
          },
        }).unwrap();
      } else {
        res = await updateWIP({
          projectId,
          approval: {
            ...approval,
            approvalStatus: WIP_APPROVAL_STATUS.APPROVED,
            isSubmitted: true,
          },
        }).unwrap();
      }
    } catch (e) {
      errorToast(TOAST_MESSAGES.WIP_SAVED_ERROR);
    }
    if (!res?.success) {
      errorToast(TOAST_MESSAGES.WIP_SAVED_ERROR);
    } else {
      closeModal();
      successToast(TOAST_MESSAGES.WIP_APPROVED_SUCCESSFULLY);
    }
  };

  return (
    <ArModal
      open={open}
      onCancel={onCancel}
      title={heading}
      footer={
        <>
          <ArButton type={ButtonTypes.OutlinedApprove} onClick={closeModal}>
            {STRING_CONSTANTS.DONT_APPROVE}
          </ArButton>
          <ArButton type={ButtonTypes.Approve} onClick={onApproveClick} loading={isLoading || isConsultingLoading}>
            {STRING_CONSTANTS.YES_APPROVE}
          </ArButton>
        </>
      }
    >
      <div className="pb-16">
        <p className="pb-20">{text}</p>
      </div>
    </ArModal>
  );
};

export const WIPReSubmitModal = ({
  open,
  onCancel,
  approval,
  closeModal,
  wipType,
  projectId,
}: WIPDeclineModalProps) => {
  const [updateWIP, { isLoading }] = useUpdateWipDetailsMutation();
  const [updateConsultingWIP, { isLoading: consultingLoading }] = useUpdateConsultingWipDetailsMutation();
  const { errorToast } = useToastService();

  const heading = STRING_CONSTANTS.SUBMIT_CONFIMATION;

  const text = STRING_CONSTANTS.SUBMIT_CONFIMATION_TEXT;

  const onSubmitClick = async () => {
    let res;
    try {
      if (wipType === "CONSULTING") {
        res = await updateConsultingWIP({
          projectId,
          approval: {
            ...approval,
            approvalStatus: WIP_APPROVAL_STATUS.PENDING,
            isSubmitted: true,
          },
        }).unwrap();
      } else {
        res = await updateWIP({
          projectId,
          approval: {
            ...approval,
            approvalStatus: WIP_APPROVAL_STATUS.PENDING,
            isSubmitted: true,
          },
        }).unwrap();
      }
    } catch (e) {
      errorToast(TOAST_MESSAGES.WIP_SAVED_ERROR);
    }
    if (!res?.success) {
      errorToast(TOAST_MESSAGES.WIP_SAVED_ERROR);
    } else {
      closeModal();
    }
  };

  return (
    <ArModal
      open={open}
      onCancel={onCancel}
      title={heading}
      footer={
        <>
          <ArButton type={ButtonTypes.Outlined} onClick={closeModal}>
            {STRING_CONSTANTS.DONT_SUBMIT}
          </ArButton>
          <ArButton type={ButtonTypes.Primary} onClick={onSubmitClick} loading={isLoading || consultingLoading}>
            {STRING_CONSTANTS.YES_SUBMIT}
          </ArButton>
        </>
      }
    >
      <div className="pb-16">
        <p className="pb-20">{text}</p>
      </div>
    </ArModal>
  );
};

export const DeleteDraftWIPModal = ({
  open,
  onCancel,
  closeModal,
  wipType,
  projectId,
  actionAfterDeletion,
}: WIPDeclineModalProps) => {
  const [deletetWIP, { isLoading }] = useDeleteDraftWIPMutation();
  const [deleteConsultingWIP, { isLoading: isConsultingLoading }] = useDeleteConsultingWipMutation();
  const { errorToast, successToast } = useToastService();

  const heading = STRING_CONSTANTS.FINAL_CONF;

  const text = STRING_CONSTANTS.DEL_DRAFT_WIP_TEXT;

  const onDeclineClick = async () => {
    let res;
    try {
      if (wipType === "CONSULTING") {
        res = await deleteConsultingWIP(projectId || "").unwrap();
      } else {
        res = await deletetWIP(projectId || "").unwrap();
      }
    } catch (e) {
      errorToast(TOAST_MESSAGES.WIP_DELETE_ERROR);
    }
    if (!res?.success) {
      errorToast(TOAST_MESSAGES.WIP_DELETE_ERROR);
    } else {
      closeModal();
      successToast(TOAST_MESSAGES.DRAFT_WIP_DELETED);
      actionAfterDeletion?.();
    }
  };
  return (
    <ArModal
      open={open}
      onCancel={onCancel}
      title={heading}
      footer={
        <>
          <ArButton type={ButtonTypes.Secondary} onClick={closeModal}>
            {STRING_CONSTANTS.CANCEL}
          </ArButton>
          <ArButton type={ButtonTypes.Reject} onClick={onDeclineClick} loading={isLoading || isConsultingLoading}>
            {STRING_CONSTANTS.YES_CONFIRM}
          </ArButton>
        </>
      }
    >
      <div className="pb-16">
        <p className="pb-20">{text}</p>
      </div>
    </ArModal>
  );
};
export const ShopOrLLDetailsMissingModal = ({ ...props }: ArModalProps) => {
  return (
    <ArModal
      title={STRING_CONSTANTS.SHOP_LL_SKIPPED}
      okText={STRING_CONSTANTS.UNDERSTOOD}
      cancelText={STRING_CONSTANTS.GO_BACK_BTN}
      {...props}
    >
      <p>{STRING_CONSTANTS.ENTER_MISSING_WIP_DETAILS_TEXT}</p>
    </ArModal>
  );
};
export default WIPReSubmitModal;
