import { ArTag } from "@anarock/ar-common-react";
import { ITableObj } from "app/services/analytics";
import { ALLOCATION_STATUS, ALLOCATION_STATUS_IN_TABLE } from "constants/index";
import React, { ReactNode } from "react";

import { AllocationDropdown } from "../AllocationDropdown";

export const getStatusCellUI = (allocationStatus: string, irf: ITableObj) => {
  let UINode: ReactNode = "-";

  const allocationDropdown = <AllocationDropdown status={allocationStatus} irf={irf} />;

  switch (allocationStatus) {
    case ALLOCATION_STATUS.ALLOCATION_PENDING:
      UINode = (
        <div className="display-flex-space-between">
          <ArTag type="default" varient={"warning"}>
            {ALLOCATION_STATUS_IN_TABLE.ALLOCATION_PENDING.text}
          </ArTag>
          {allocationDropdown}
        </div>
      );
      break;
    case ALLOCATION_STATUS.POOLED_ALLOCATION:
      UINode = (
        <div className="display-flex-space-between">
          <ArTag type="default" varient={"primary"}>
            {ALLOCATION_STATUS_IN_TABLE.POOLED_ALLOCATION.text}
          </ArTag>
          {allocationDropdown}
        </div>
      );
      break;
    case ALLOCATION_STATUS.FULLY_ALLOCATED:
      UINode = (
        <div className="display-flex-space-between">
          <ArTag type="default" varient={"success"}>
            {ALLOCATION_STATUS_IN_TABLE.FULLY_ALLOCATED.text}
          </ArTag>
          {allocationDropdown}
        </div>
      );
      break;
    default:
      break;
  }

  return UINode;
};
