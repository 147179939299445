import { ArButton, ButtonTypes } from "@anarock/ar-common-react";
import { IResetApplyButtonComponentProps } from "components/filterBar/index.interface";
import { STRING_CONSTANTS } from "constants/StringConstants";
import React from "react";

import styles from "../../index.module.scss";

const ResetApplyButtonComponent: React.FC<IResetApplyButtonComponentProps> = ({ handleApply, handleReset }) => {
  return (
    <div className={`display-flex-space-between ${styles.filterButtonContainer}`}>
      <ArButton type={ButtonTypes.Link} size="middle" onClick={handleReset}>
        {STRING_CONSTANTS.RESET}
      </ArButton>
      <ArButton size="middle" onClick={handleApply}>
        {STRING_CONSTANTS.APPLY}
      </ArButton>
    </div>
  );
};

export default ResetApplyButtonComponent;
