import { ArBreadCrumb, ArButton, ArTag, ButtonTypes } from "@anarock/ar-common-react";
import { FormNode, nodeType } from "@anarock/ar-common-react";
import { ArBreadCrumbItem } from "@anarock/ar-common-react/dist/ui-components/ar-breadcrumb";
import { NotificationOutlined } from "@ant-design/icons";
import { IBillingMilestone } from "app/services/wip";
import { getFormattedDateOrDash } from "app/utils/helperFunctions";
import { IWIPApproval } from "app/utils/interfaces/index.interface";
import { WIP_APPROVAL_SUB_STATUS } from "constants/index";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { USER_ROLE_NAME } from "constants/Taboptions";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import globalStyles from "../../global.module.scss";
import { CreateWIPFooterProps } from "./index.interface";
import styles from "./index.module.scss";

export const WipBreadCrumb: React.FC<{ path: string }> = ({ path }) => {
  // const navigate = useNavigate();
  // todo breadcrubs to be sorted later
  // const userFlow = useGetWipFlow();

  return (
    <ArBreadCrumb className={styles.breadCrumb}>
      <ArBreadCrumbItem className={styles.breadCrumbItem}>{path}</ArBreadCrumbItem>
    </ArBreadCrumb>
  );
};

export const getFormLayout = (childNode: FormNode[]) => {
  return {
    type: nodeType.container,
    elementData: {
      outerContainerClassName: styles.reqOuterContainer,
    },
    childNode: {
      type: nodeType.array,
      childNode,
    },
  };
};

export const getWIPForm = (childNode: FormNode[], title: string) => {
  return {
    type: nodeType.container,
    elementData: {
      title,
      titleClassname: `${globalStyles.formTitle} formTitle`,
    },
    childNode: {
      type: nodeType.array,
      childNode,
    },
  };
};

export const CreateWipFooter = ({
  prevButtonText = STRING_CONSTANTS.DONT_CREATE,
  nextButtonText = STRING_CONSTANTS.NEXT_STEP,
  middleButtonText = STRING_CONSTANTS.SAVE_PROGRESS,
  nextButtonAction,
  showMiddleButton = true,
  middleButtonAction,
  middleButtonProps,
  nextButtonProps,
  prevButtonProps,
  prevButtonAction,
}: CreateWIPFooterProps) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (prevButtonAction) {
      prevButtonAction();
      return;
    }
    navigate(-1);
  };
  return (
    <div className={`${styles.submitButtonCtn} display-flex-space-between`}>
      <ArButton size="large" type={ButtonTypes.Outlined} onClick={goBack} {...prevButtonProps}>
        {prevButtonText}
      </ArButton>
      <div>
        {showMiddleButton && (
          <ArButton
            size="large"
            type={ButtonTypes.Outlined}
            onClick={() => middleButtonAction && middleButtonAction()}
            {...middleButtonProps}
          >
            {middleButtonText}
          </ArButton>
        )}
        <ArButton
          size="large"
          className={styles.btnWidth}
          onClick={() => nextButtonAction && nextButtonAction()}
          {...nextButtonProps}
        >
          {nextButtonText}
        </ArButton>
      </div>
    </div>
  );
};

export const getInfoStripForApproval = (
  userSubRoles?: (typeof USER_ROLE_NAME)[keyof typeof USER_ROLE_NAME][],
  approval?: IWIPApproval
) => {
  let UINode: ReactNode = null;
  if (userSubRoles?.includes(USER_ROLE_NAME.BRAND_MANAGER_LEAD)) {
    switch (approval?.approvalSubStatus) {
      case WIP_APPROVAL_SUB_STATUS.APPROVED:
        UINode = (
          <ArTag type="full-width" varient={"success"}>
            <NotificationOutlined /> {STRING_CONSTANTS.LIVE_WIP_BM_LEAD_TEXT}
          </ArTag>
        );
        break;

      case WIP_APPROVAL_SUB_STATUS.PENDING:
        UINode = (
          <ArTag type="full-width" varient={"warning"}>
            <NotificationOutlined /> {STRING_CONSTANTS.NEW_WIP_APPROVAL_BM_LEAD_TEXT}
          </ArTag>
        );
        break;

      case WIP_APPROVAL_SUB_STATUS.PENDING_CHANGES:
        UINode = (
          <ArTag type="full-width" varient={"warning"}>
            <NotificationOutlined /> {STRING_CONSTANTS.COMPARING_BM_CHAMGES_BM_LEAD_TEXT}
          </ArTag>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DECLINED:
        UINode = (
          <ArTag type="full-width" varient={"error"}>
            <NotificationOutlined /> {STRING_CONSTANTS.REASON_FOR_DECLINE_TEXT} {approval.comment}.
          </ArTag>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DECLINED_CHANGES:
        UINode = (
          <ArTag type="full-width" varient={"error"}>
            <NotificationOutlined /> {STRING_CONSTANTS.REASON_FOR_DECLINE_TEXT} {approval.comment}.
          </ArTag>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DROPPED:
        UINode = (
          <ArTag type="full-width" varient={"error"}>
            <NotificationOutlined /> {STRING_CONSTANTS.REASON_FOR_DROPPED_TEXT} {approval.comment}.
          </ArTag>
        );
        break;
      default:
        break;
    }
    return UINode;
  } else if (userSubRoles?.includes(USER_ROLE_NAME.BRAND_MANAGER_ASSOCIATE)) {
    switch (approval?.approvalSubStatus) {
      case WIP_APPROVAL_SUB_STATUS.APPROVED:
        UINode = (
          <ArTag type="full-width" varient={"success"}>
            <NotificationOutlined /> {STRING_CONSTANTS.LIVE_WIP_BM_TEXT}
          </ArTag>
        );
        break;

      case WIP_APPROVAL_SUB_STATUS.PENDING:
        UINode = (
          <ArTag type="full-width" varient={"warning"}>
            <NotificationOutlined /> {STRING_CONSTANTS.PENDING_WIP_BM_TEXT}
          </ArTag>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.PENDING_NO_SUBMISSION:
        UINode = (
          <ArTag type="full-width" varient={"warning"}>
            <NotificationOutlined /> {STRING_CONSTANTS.PENDING_CHANGES_WIP_BM_TEXT}
          </ArTag>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.PENDING_CHANGES:
        UINode = (
          <ArTag type="full-width" varient={"warning"}>
            <NotificationOutlined /> {STRING_CONSTANTS.PENDING_CHANGES_WIP_BM_TEXT}
          </ArTag>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DECLINED:
        UINode = (
          <ArTag type="full-width" varient={"error"}>
            <NotificationOutlined /> {STRING_CONSTANTS.REASON_FOR_DECLINE_TEXT} {approval.comment}.
          </ArTag>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DECLINED_NO_SUBMISSION:
        UINode = (
          <ArTag type="full-width" varient={"error"}>
            <NotificationOutlined /> {STRING_CONSTANTS.REASON_FOR_DECLINE_TEXT} {approval.comment}.
          </ArTag>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DECLINED_CHANGES:
        UINode = (
          <ArTag type="full-width" varient={"error"}>
            <NotificationOutlined /> {STRING_CONSTANTS.REASON_FOR_DECLINE_TEXT} {approval.comment}.
          </ArTag>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DROPPED:
        UINode = (
          <ArTag type="full-width" varient={"error"}>
            <NotificationOutlined /> {STRING_CONSTANTS.REASON_FOR_DROPPED_TEXT} {approval.comment}.
          </ArTag>
        );
        break;
      default:
        break;
    }
    return UINode;
  }
  return UINode;
};

export const getFooterButtons = (
  userSubRoles?: (typeof USER_ROLE_NAME)[keyof typeof USER_ROLE_NAME][],
  approval?: IWIPApproval,
  goBack?: () => void,
  submitForApproval?: () => void,
  handleApproveWip?: () => void,
  handleDeclineWip?: () => void
) => {
  let UINode: ReactNode = null;
  if (userSubRoles?.includes(USER_ROLE_NAME.BRAND_MANAGER_ASSOCIATE)) {
    switch (approval?.approvalSubStatus) {
      case WIP_APPROVAL_SUB_STATUS.APPROVED:
        UINode = (
          <div>
            <ArButton size="large" type={ButtonTypes.Primary} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
          </div>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.PENDING:
        UINode = (
          <div>
            <ArButton size="large" type={ButtonTypes.Primary} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
          </div>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.PENDING_CHANGES:
        UINode = (
          <div>
            <ArButton size="large" type={ButtonTypes.Primary} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
          </div>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.PENDING_NO_SUBMISSION:
        UINode = (
          <div className={`${styles.submitButtonCtn} display-flex-space-between`}>
            <ArButton size="large" type={ButtonTypes.Outlined} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
            <ArButton size="large" onClick={submitForApproval}>
              {STRING_CONSTANTS.SUBMIT_FOR_APPROVAL}
            </ArButton>
          </div>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DECLINED:
        UINode = (
          <div className={`${styles.submitButtonCtn} display-flex-space-between`}>
            <ArButton size="large" type={ButtonTypes.Outlined} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
            <ArButton size="large" onClick={submitForApproval}>
              {STRING_CONSTANTS.RE_SUBMIT_FOR_APPROVAL}
            </ArButton>
          </div>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DECLINED_NO_SUBMISSION:
        UINode = (
          <div className={`${styles.submitButtonCtn} display-flex-space-between`}>
            <ArButton size="large" type={ButtonTypes.Outlined} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
            <ArButton size="large" onClick={submitForApproval}>
              {STRING_CONSTANTS.RE_SUBMIT_FOR_APPROVAL}
            </ArButton>
          </div>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DECLINED_CHANGES:
        UINode = (
          <div className={`${styles.submitButtonCtn} display-flex-space-between`}>
            <ArButton size="large" type={ButtonTypes.Outlined} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
            <ArButton size="large" onClick={submitForApproval}>
              {STRING_CONSTANTS.RE_SUBMIT_FOR_APPROVAL}
            </ArButton>
          </div>
        );
        break;
      default:
        break;
    }
  } else if (userSubRoles?.includes(USER_ROLE_NAME.BRAND_MANAGER_LEAD)) {
    switch (approval?.approvalSubStatus) {
      case WIP_APPROVAL_SUB_STATUS.APPROVED:
        UINode = (
          <div>
            <ArButton size="large" type={ButtonTypes.Primary} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
          </div>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.PENDING:
        UINode = (
          <div className={`${styles.submitButtonCtn} display-flex-space-between`}>
            <ArButton size="large" type={ButtonTypes.Outlined} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
            <div>
              <ArButton size="large" type={ButtonTypes.OutlinedRejected} onClick={handleDeclineWip}>
                {STRING_CONSTANTS.DECLINE}
              </ArButton>
              <ArButton size="large" onClick={handleApproveWip}>
                {STRING_CONSTANTS.APPROVE}
              </ArButton>
            </div>
          </div>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.PENDING_CHANGES:
        UINode = (
          <div className={`${styles.submitButtonCtn} display-flex-space-between`}>
            <ArButton size="large" type={ButtonTypes.Outlined} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
            <div>
              <ArButton size="large" type={ButtonTypes.OutlinedRejected} onClick={handleDeclineWip}>
                {STRING_CONSTANTS.DECLINE}
              </ArButton>
              <ArButton size="large" onClick={handleApproveWip}>
                {STRING_CONSTANTS.APPROVE}
              </ArButton>
            </div>
          </div>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DECLINED:
        UINode = (
          <div className={`${styles.submitButtonCtn} display-flex-space-between`}>
            <ArButton size="large" type={ButtonTypes.Outlined} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
            <ArButton size="large" onClick={handleApproveWip}>
              {STRING_CONSTANTS.APPROVE}
            </ArButton>
          </div>
        );
        break;
      case WIP_APPROVAL_SUB_STATUS.DECLINED_CHANGES:
        UINode = (
          <div className={`${styles.submitButtonCtn} display-flex-space-between`}>
            <ArButton size="large" type={ButtonTypes.Outlined} onClick={goBack}>
              {STRING_CONSTANTS.GO_BACK_BTN}
            </ArButton>
            <ArButton size="large" onClick={handleApproveWip}>
              {STRING_CONSTANTS.APPROVE}
            </ArButton>
          </div>
        );
        break;
      default:
        break;
    }
  }

  return UINode;
};

export const getBillingMilestoneTooltipText = (billingMilestones: IBillingMilestone[]) => {
  return billingMilestones?.length
    ? billingMilestones?.map(({ value, dueDate }, idx) => (
        <p key={idx}>
          {idx + 1}. {value} ({getFormattedDateOrDash(dueDate)})
        </p>
      ))
    : "-";
};

export const getBillingMilstoneText = (billingMilestones: IBillingMilestone[]) => {
  return billingMilestones?.length ? billingMilestones?.map(({ value }) => value + ", ") : "-";
};
